export const firebaseConfig = {
  apiKey: "AIzaSyBIkKJY49lg4grJSPy99Iy3UBS-Rlc_Lsc",
  authDomain: "wishlist-web-dffa6.firebaseapp.com",
  databaseURL: "https://wishlist-web-dffa6.firebaseio.com",
  projectId: "wishlist-web-dffa6",
  storageBucket: "wishlist-web-dffa6.appspot.com",
  messagingSenderId: "465165781006",
  appId: "1:465165781006:web:b9144394413530a688d45f",
  measurementId: "G-6Z1KTQHE18"
};
