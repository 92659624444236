import { myAuth, myFirestore, myFirebase, serverTimestamp } from "./";

// helper for addFieldsForAdd and updateGift
// build object with only valid fields passed in as prop
const getFieldsToUpdate = (
  item_title,
  item_description,
  url,
  price_currency,
  price,
  wishlist_id,
  notes
) => {
  console.log('getFieldsToUpdate');
  let giftFields = {};
  giftFields['wishlist_id'] = wishlist_id;
  giftFields['item_title'] = item_title;
  if(url) {
    giftFields['url'] = url;
  }

  if(item_description) {
    giftFields['item_description'] = item_description;
  }
  if(notes) {
    giftFields['notes'] = notes;
  }
  if(price_currency && price) {
    giftFields['price_currency'] = price_currency;
    giftFields['price'] = price;
  }
  return giftFields;
}

const addFieldsForAdd = (giftFieldsOriginal) => {
  let giftFields = giftFieldsOriginal;
  giftFields['status'] = 'active';
  giftFields['creator_user_id'] = myAuth.currentUser.uid;
  giftFields['time_created_at'] = serverTimestamp;
  giftFields['time_last_edit_at'] = serverTimestamp;
  return giftFields;
}


// can be called with subset of props e.g. addGiftToWishlist(item_title)
export const addGiftToWishlist = async ({
  item_title,
  item_description,
  url,
  price_currency,
  price,
  wishlist_id,
  notes,
  totalGiftsAdded
}) => {
  console.log('addGiftToWishlist - start');
  if (!item_title) {
    console.log('addGiftToWishlist: should probably add error or toaster or something');
    return null;
  }
  let giftFields = getFieldsToUpdate(
    item_title,
    item_description,
    url,
    price_currency,
    price,
    wishlist_id,
    notes
  );
  giftFields = addFieldsForAdd(giftFields);

  // Get a new write batch
  const batch = myFirestore.batch();

  const newGiftRef = myFirestore
  .collection('wishlists').doc(wishlist_id)
  .collection('gifts').doc();
  batch.set(newGiftRef, giftFields);

  const usersRef = myFirestore
  .collection('users').doc(myAuth.currentUser.uid);
  batch.set(
    usersRef,
    {
      totalGiftsAdded: totalGiftsAdded + 1,
      time_last_write_at: serverTimestamp
    },
    { merge: true }
  );

  await batch.commit(); // error is currently successfuly caught in AddGiftModal

  myFirebase.analytics().logEvent('gift_added');

  return newGiftRef;
}

export const deleteGift = async (wishlist_id, gift_id) => {
  console.log('deleteGift - wishlist_id: ' + wishlist_id + '   gift_id: ' + gift_id);
  try {
    const value = await myFirestore.collection('wishlists').doc(wishlist_id).collection('gifts').doc(gift_id).delete();
    return true;
  } catch (error) {
    console.log(error);
    return error;
  }


}

export const listenToGiftsInWishlist = (wishlist_id, observer) => {
  console.log('listenToGiftsInWishlist');
  // https://blog.logrocket.com/react-hooks-with-firebase-firestore/
  return myFirestore.collection('wishlists')
  .doc(wishlist_id)
  .collection('gifts')
  .orderBy('time_last_edit_at')
  .onSnapshot(observer);
};
