import { myAuth, myFirestore, myFirebase, serverTimestamp } from "./";

// helper for createWishlist and updateWishlist
// build object with only valid fields passed in as prop
export const getFieldsToUpdate = (created_for, creator_first_name, creator_last_name, recipient_first_name, recipient_last_name) => {
  console.log('getFieldsToUpdate');
  let wishlistFields = {};
  if(created_for) {
    wishlistFields['created_for'] = created_for;
  }
  if(creator_first_name) {
    wishlistFields['creator_first_name'] = creator_first_name;
  }
  if(creator_last_name) {
    wishlistFields['creator_last_name'] = creator_last_name;
  }
  if(recipient_first_name) {
    wishlistFields['recipient_first_name'] = recipient_first_name;
  }
  if(recipient_last_name) {
    wishlistFields['recipient_last_name'] = recipient_last_name;
  }
  return wishlistFields;
}

export const addFieldsForCreate = (wishlistFieldsOriginal) => {
  let wishlistFields = wishlistFieldsOriginal;
  wishlistFields['status'] = 'active';
  wishlistFields['creator_user_id'] = myAuth.currentUser.uid;
  wishlistFields['time_created_at'] = serverTimestamp;
  wishlistFields['time_last_edit_at'] = serverTimestamp;
  return wishlistFields;
}

// can be called with subset of props e.g. createWishlist(createdFor, name_last)
export const createWishlist = async ({
  created_for,
  creator_first_name, creator_last_name,
  recipient_first_name, recipient_last_name, // could be undefined
  totalWishlistsAdded
}) => {

  // Get a new write batch
  const batch = myFirestore.batch();

  const newWishlistRef = myFirestore.collection('wishlists').doc();

  let wishlist = {
    status: 'active',
    creator_user_id: myAuth.currentUser.uid,
    time_created_at: serverTimestamp,
    time_last_edit_at: serverTimestamp,
    creator_first_name,
    creator_last_name,
    created_for
  }
  if(recipient_first_name) wishlist.recipient_first_name = recipient_first_name;
  if(recipient_last_name) wishlist.recipient_last_name = recipient_last_name;

  batch.set(newWishlistRef, wishlist);

  const usersRef = myFirestore
  .collection('users').doc(myAuth.currentUser.uid);
  batch.set(
    usersRef,
    {
      totalWishlistsAdded: totalWishlistsAdded + 1,
      time_last_write_at: serverTimestamp
    },
    { merge: true }
  );

  await batch.commit(); // error is currently successfuly caught in WishlistCreationFlow

  myFirebase.analytics().logEvent('wishlist_created', {
    created_for
  });

  return newWishlistRef;
}




export const listenToWishlist = (wishlist_id, observer) => {
  // https://blog.logrocket.com/react-hooks-with-firebase-firestore/
  return myFirestore.collection('wishlists').doc(wishlist_id).onSnapshot(observer);
};
