import {
  LISTEN_TO_GROUP_SUCCESS
} from "../actions/";


const INITIAL_STATE = {
  santa_group_id:'',
  following: null,
  group_name:'',
  matching_user_name:'',
  matching_user_santa_uid:'',
  num_group_members:'',
  price_max:'',
  price_min:''
}

export default ( state = INITIAL_STATE, action ) => {
  switch (action.type) {
    case LISTEN_TO_GROUP_SUCCESS:
    return {
      ...state,
      santa_group_id: action.payload.santa_group_id,
      following: action.payload.group.following,
      group_name: action.payload.group.group_name,
      matching_user_name: action.payload.group.matching_user_name,
      matching_user_santa_uid: action.payload.group.matching_user_santa_uid,
      num_group_members: action.payload.group.num_group_members,
      price_max: action.payload.group.price_max,
      price_min: action.payload.group.price_min
    };


    default:
    return state;
  }
};
