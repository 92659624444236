import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQueryParam, StringParam } from 'use-query-params';
import { useHistory } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import WishlistsList from './WishlistsList';

import { requestingSecretSantaDetails } from "../actions";
import { receivedSecretSantaDetails } from "../actions";

import SantaURLToStateLoader from "../common/SantaURLToStateLoader"

const useStyles = makeStyles({
  "@global": {
    body: {
      backgroundColor: "#fff"
    }
  },
  "buttonBox": {
    backgroundColor: "#f0ec90",
  },
  "button": {
    margin: 10
  }
});

const SantasSecretKeeperPage = () => {
  // redux state
  const haveRequestedDetailsOnce = useSelector(state => state.pendingGroupFollower.haveRequestedDetailsOnce);
  console.log('POSSIBLE EDGE CASE with haveRequestedDetailsOnce');
  console.log('If someone with an active session tries to click link from ss email this may prevent it from being attempted');
  const group_name = useSelector(state => state.pendingGroupFollower.group_name);
  const matching_user_name = useSelector(state => state.pendingGroupFollower.matching_user_name);
  const num_group_members = useSelector(state => state.pendingGroupFollower.num_group_members);
  const wishlists = useSelector(state => state.auth.wishlists);
  const dispatch = useDispatch();

  const classes = useStyles();

  let history = useHistory();

  // optional url params
  const [gidParam, setGidParam] = useQueryParam('gid', StringParam);
  const [gmidParam, setGmidParam] = useQueryParam('gmid', StringParam);

  const handleCreateNewWishlist = () => {
    history.push('/create-wishlist?gid='+gidParam+'&gmid='+gmidParam);
  }

  const handleViewGroupButton = () => {
    history.push('/group/' + gidParam);
  }

  return (
    <div>
      <Container maxWidth="md" className={classes.container}>
        <Box
          alignItems="center"
          textAlign="center"
          my={1}
          p={1}
        >
          <Typography component="h1" variant="h4">
            Share gift ideas with your secret santa
          </Typography>
          <Typography component="h1" variant="subtitle1">
            Giftwish lets you create wishlists for any occation
            {/* Create a wishlist and share it with your secret santa group */}
          </Typography>
        </Box>
        <Box align='center'>
          <Box display='inline-flex' flexDirection='row' alignItems='center'>
            <img
              src={require('../assets/Santa_Transparent_Shhh.png')}
              alt="santa"
              height="100px"
              width="100px"
            />
            <Box p='100'>
              <Typography variant='h5'>
                + Giftwish
              </Typography>
            </Box>
          </Box>
        </Box>
        {wishlists && Object.keys(wishlists).length > 0 && (
          <Box
            alignItems="center"
            textAlign="center"
            my={2}
            p={1}
          >
            <WishlistsList gid={gidParam} gmim={gmidParam} />
          </Box>
        )}
        <Box
          alignItems="center"
          textAlign="center"
          my={1}
          py={1}
          bgcolor="#e8f5e9"
        >
          <Box
            alignItems="center"
            textAlign="center"
            my={1}
            p={1}
          >
            {(group_name && matching_user_name )
              ? (
                <div>
                  <Typography component="h1" variant="body1">
                    Create a wishlist to be shared with your secret santa:
                  </Typography>
                  <Typography component="h1" variant="body1">
                    <Box fontWeight='fontWeightMedium' display='inline'>{group_name}</Box> (created by <Box fontWeight='fontWeightMedium' display='inline'>{matching_user_name}</Box>)
                  </Typography>
                </div>
              )
              :(
                <div>
                  <Typography component="h1" variant="body1">
                    Create a wishlist to be shared with your secret santa group:
                  </Typography>
                  <Typography component="h1" variant="body1">
                    loading group details...
                  </Typography>
                </div>
              )}
            <Button
              variant="contained"
              type="button"
              onClick={handleCreateNewWishlist}
              color="primary"
              className={classes.button}
            >
              Create new wishlist
            </Button>
            <Button
              type="button"
              color="primary"
              onClick={handleViewGroupButton}
            >
              View Group
            </Button>
          </Box>

        </Box>


      </Container>
        <SantaURLToStateLoader />
      </div>
      );
      }

      export default SantasSecretKeeperPage;
