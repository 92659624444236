import {
  GIFT_CREATION_MODAL_TOGGLE,
  GIFT_CREATION_INFO_CHANGED,
  CLEAR_GIFT_CREATION_INFO
} from "../actions/";


const INITIAL_STATE = {
  showAddGiftModal: false,
  item_title: '',
  item_description: '',
  url: '',
  merchant: '',
  quantity: null,
  price_currency: '',
  price: null
}

export default ( state = INITIAL_STATE, action ) => {
  switch (action.type) {
    case GIFT_CREATION_MODAL_TOGGLE:
      return {
        ...state,
        showAddGiftModal: !state.showAddGiftModal
      }

    case GIFT_CREATION_INFO_CHANGED:
      return {
        ...state,
        [action.payload.prop]:
        action.payload.value
      };

    case CLEAR_GIFT_CREATION_INFO:
      return INITIAL_STATE;

    default:
      return state;
  }
};
