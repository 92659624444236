import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import GroupIcon from '@material-ui/icons/Group';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import GiftList from './GiftList';
import { receivedWishlistUpdate } from "../actions";
import { listenToWishlist, myAuth, myFirebase } from "../firebase";

import AddSantaFollowerModal from './AddSantaFollowerModal';
import { toggleAddSantaGroupFollowerModal } from "../actions";
import { toggleAddGiftModal } from "../actions";

import SantaURLToStateLoader from "../common/SantaURLToStateLoader"

const useStyles = makeStyles({
  "@global": {
    body: {
      backgroundColor: "#fff"
    }
  }

});

const WishlistPage = () => {
  let { wishlist_id } = useParams();  // react router for required params

  // redux state
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  // redux wishlist state
  const wishlist_id_in_state = useSelector(state => state.wishlist.wishlist_id);
  const created_for = useSelector(state => state.wishlist.created_for);
  const creator_first_name = useSelector(state => state.wishlist.creator_first_name);
  const creator_last_name = useSelector(state => state.wishlist.creator_last_name);
  const recipient_first_name = useSelector(state => state.wishlist.recipient_first_name);
  const recipient_last_name = useSelector(state => state.wishlist.recipient_last_name);
  const time_created_at = useSelector(state => state.wishlist.time_created_at);
  const time_last_edit_at = useSelector(state => state.wishlist.time_last_edit_at);
  const creator_user_id = useSelector(state => state.wishlist.creator_user_id);
  const followers = useSelector(state => state.wishlist.followers);
  const gifts = useSelector(state => state.wishlist.gifts);

  // redux santa state
  const showAddSantaGroupFollowerModal = useSelector(state => state.pendingGroupFollower.showAddSantaGroupFollowerModal);
  const group_name = useSelector(state => state.pendingGroupFollower.group_name);
  const matching_user_name = useSelector(state => state.pendingGroupFollower.matching_user_name);
  const gid = useSelector(state => state.pendingGroupFollower.gid);
  const num_group_members = useSelector(state => state.pendingGroupFollower.num_group_members);
  const gmid = useSelector(state => state.pendingGroupFollower.gmid);

  const dispatch = useDispatch();



  const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: 'blue'
  },
  "buttonBox": {
    backgroundColor: "#f0ec90",
  },
  "button": {
    margin: 10
  }
}));

const classes = useStyles();

const history = useHistory();

useEffect(() => {
  if(isAuthenticated && creator_user_id == myAuth.currentUser.uid){
    myFirebase.analytics().logEvent('view_my_wishlist', {
      wishlist_id: wishlist_id_in_state
    });
  } else {
    myFirebase.analytics().logEvent('view_someone_elses_wishlist', {
      wishlist_id: wishlist_id_in_state
    });
  }
  }, [history]);

  useEffect(() => {
    // https://blog.logrocket.com/react-hooks-with-firebase-firestore/
    const observer = {
      next: querySnapshot => {
        const updatedWishlist = querySnapshot.data();
        console.log('observer: updatedWishlist');
        console.log(updatedWishlist);
        console.log(wishlist_id);
        dispatch(receivedWishlistUpdate(updatedWishlist, wishlist_id));
      },
      // error: () => setError('grocery-list-item-get-fail')
      error: () => console.log('ERROR in useEffect')
    };
    const unsubscribe = listenToWishlist(wishlist_id, observer);
    // in useEffect, return a function that "cleans up the previous effects before applying the next effects"
    return unsubscribe;
  },
  // useEffect has optional second argument - the dependancy array
  // "You can tell React to skip applying an effect if certain values haven’t changed between re-renders"
  // this improves performance
  [wishlist_id, dispatch]
  // DO I NEED TO ADD WISHLIST OR GIFTS ITSELF?
  // SHOULD I ADD THIS OPTIMIZATION TO OTHER useEffect in other components?
  // "Any props or state variables that are used in the function from the first parameter need to be listed as dependencies"
  // https://blog.logrocket.com/react-hooks-with-firebase-firestore/
);

useEffect(() => {
  console.log('useEffect 2');
  if(isAuthenticated && wishlist_id_in_state){ // wishlist redux is loaded
    if(haveSantaFollowerToAdd()){
      console.log('haveSantaFollowerToAdd true');
      if(!showAddSantaGroupFollowerModal){
        console.log('showAddSantaGroupFollowerModal false');
        dispatch(toggleAddSantaGroupFollowerModal());
      }
    }
  }
},[isAuthenticated, wishlist_id_in_state]
)

const haveSantaFollowerToAdd = () => {
  return (myAuth.currentUser.uid === creator_user_id && // user is the wishlist creator
    gmid && group_name && matching_user_name && gid && num_group_members // santa data in state, could make into util function
    && !(followers && Object.keys(followers).includes(gid)) // gid isn't already a follower
    // tough edge cases exists where if you click into other wishlist and then shared one the old follower data is still in redux
    // this problem is unsovled but isn't worth solving
  )
}

const renderTitle = () => {
  if(recipient_first_name) {
    let recipient_name = recipient_first_name;
    if(recipient_last_name) recipient_name = recipient_name + ' ' + recipient_last_name;
    return (
      <div>
        <Typography component="h1" variant="h4" align="center">
          Welcome to {recipient_name}'s wishlist{(isAuthenticated && myAuth.currentUser.uid === creator_user_id) && ('!')}
        </Typography>
        <Typography component="h1" variant="subtitle1" align="center">
          Managed by {creator_first_name} {creator_last_name}
        </Typography>
      </div>
    )
  }  else {
    return (
      <Typography className={classes.pageTitle} component="h1" variant="h4" align="center">
        Welcome to {creator_first_name} {creator_last_name}'s wishlist{!(isAuthenticated && myAuth.currentUser.uid === creator_user_id) && ('!')}
      </Typography>
    )
  }
}


const handleCreateNewWishlist = () => {
  history.push('/create-wishlist?gid='+gid+'&gmid='+gmid);
}

const handleGroupClick = (id) => {
  history.push('/group/' + id)
}

const renderFollowerBox = () => {
  if( // if this wishlist is shared with withlist
    followers
    && Object.keys(followers)
    && Object.keys(followers).length>0
    && followers[Object.keys(followers)[0]].follower_name
    && followers[Object.keys(followers)[0]].follower_description
  ) {
    const { follower_name, follower_description } = followers[Object.keys(followers)[0]];
    return (
      <Box alignItems="center" textAlign="center" my={1} py={1} bgcolor="#e8f5e9">
        <Typography component="h1" variant="body1">
          Shared with {follower_name}
        </Typography>
        <Typography component="h1" variant="body2">
          {follower_description}
        </Typography>
        <Button variant="contained" type="button" onClick={() => handleGroupClick(Object.keys(followers)[0])} color="primary" className={classes.button}>
          See other wishlists
        </Button>
        { // if have pending group and its the same group as the one this wishlist is shared with
          (group_name && num_group_members && gid && gmid && Object.keys(followers)[0] == gid)
            ?(
              <Button variant="contained" type="button" onClick={handleCreateNewWishlist} color="primary" className={classes.button}>
                Create wishlist
              </Button>
            )
            :(
              <Box p={1} m={1}>
                <Typography variant="body2">
                  Want to create a wishlist for this group? Go click your personlized <Box fontWeight="fontWeightBold" fontWeight='fontWeightMedium' display='inline'>Create and share your wishlist</Box> link from the Santas Secret Keeper emails
                </Typography>
              </Box>
            )
        }

      </Box>
    )
  }

  }

  return (
    <Container maxWidth="md">
      <Box
        alignItems="center"
        textAlign="center"
        my={1}
        mx={0}
        p={0}
      >
        {!wishlist_id_in_state
          ?(
            <div>
              <Typography component="h1" variant="h4" align="center">
                Loading wishlist...
              </Typography>
              <Typography component="h1" variant="subtitle1" align="center">
                This will just take a moment...
              </Typography>
              <CircularProgress />
            </div>
          )
          :(
            <div>
              {renderTitle()}
              <Box
                alignItems="center"
                textAlign="center"
                my={3}
                mx={0}
                p={0}
              >
                <GiftList />
              </Box>
              {renderFollowerBox()}
            </div>
          )
        }
      </Box>
      <AddSantaFollowerModal />
      <SantaURLToStateLoader />
      {(gifts && Object.keys(gifts).length>0) && (
        <Box>
          <Typography variant='caption' color='textSecondary'>
            Giftwish is a participant in the eBay Partner Network (EPN). When you click on links to various merchants on this site and make a purchase, this can result in this site earning a commission. Affiliate programs and affiliations include, but are not limited to, the eBay Partner Network.
          </Typography>
        </Box>
      )}

    </Container>
      );
    }

    export default WishlistPage;
