export const SHOW_ALERT = "SHOW_ALERT";
export const UPDATE_ALERT_DETAILS = "UPDATE_ALERT_DETAILS";
export const HIDE_ALERT = "HIDE_ALERT";


export const showAlert = (message, type) => dispatch => {
  dispatch({
    type: UPDATE_ALERT_DETAILS,
    payload: { prop: 'type', value: type }
  });
  dispatch({
    type: UPDATE_ALERT_DETAILS,
    payload: { prop: 'message', value: message }
  });
  dispatch({ type: SHOW_ALERT })
};

export const hideAlert = () => dispatch => {
  dispatch({ type: HIDE_ALERT })
};
