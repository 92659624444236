import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles({
  "@global": {
    body: {
      backgroundColor: "#fff"
    }
  },
  paper: {
    marginTop: 20,
    display: "flex",
    padding: 20,
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#f50057"
  },
  form: {
    marginTop: 1
  }
});

// why forwardRef?
// https://material-ui.com/guides/composition/#caveat-with-refs
// https://stackoverflow.com/questions/56347839/material-ui-v4-0-1-warning-expected-an-element-that-can-hold-a-ref
const InputForm = React.forwardRef((props, ref)  => {

  const classes = useStyles();

  const Icon = props.icon;

  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <Icon />
        </Avatar>
        <Typography component="h1" variant="h6" align="center">
          {props.title}
        </Typography>
        <Typography component="h1" variant="body1" align="center">
          {props.subtitle}
        </Typography>
        {props.children}
      </Paper>
    </Container>
  );
})

export default InputForm;
