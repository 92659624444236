import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
// "If you are creating a modal dialog, you probably want to use the Dialog component rather than directly using Modal"
// https://material-ui.com/components/dialogs/#api

import { toggleAddSantaGroupFollowerModal } from "../actions";
import { clearSecretSantaDetails } from "../actions";
import { addGroupFollowerToWishlist } from "../firebase";
import { showAlert } from "../actions";


const AddSantaFollowerModal = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  let history = useHistory();

  // redux state
  const dispatch = useDispatch();

  const showAddSantaGroupFollowerModal = useSelector(state => state.pendingGroupFollower.showAddSantaGroupFollowerModal);
  const group_name = useSelector(state => state.pendingGroupFollower.group_name);
  const matching_user_name = useSelector(state => state.pendingGroupFollower.matching_user_name);
  const gid = useSelector(state => state.pendingGroupFollower.gid);
  const num_group_members = useSelector(state => state.pendingGroupFollower.num_group_members);
  const totalGroupFollowersAdded = useSelector(state => state.auth.user.totalGroupFollowersAdded);
  const gmid = useSelector(state => state.pendingGroupFollower.gmid);

  const wishlist_id = useSelector(state => state.wishlist.wishlist_id);
  const created_for = useSelector(state => state.wishlist.created_for);
  const creator_first_name = useSelector(state => state.wishlist.creator_first_name);
  const creator_last_name = useSelector(state => state.wishlist.creator_last_name);
  const recipient_first_name = useSelector(state => state.wishlist.recipient_first_name);
  const recipient_last_name = useSelector(state => state.wishlist.recipient_last_name);


  const handleAddGroupFollower = async () => {
    console.log('handleAddGroupFollower####');
    try{
      await addGroupFollowerToWishlist({
        // save in 2 spots!  one for wishlist page load and the other for digest (+ future dashboard?)
        group_name,
        matching_user_name,
        gid,
        num_group_members,
        gmid,
        wishlist_id,
        totalGroupFollowersAdded,
        created_for,
        creator_first_name,
        creator_last_name,
        recipient_first_name,
        recipient_last_name
      });

      history.push('/wishlist/'+ wishlist_id); // clear gid and gmid from url
      dispatch(clearSecretSantaDetails()); // also sets modal to false
      dispatch(showAlert("Success! "+group_name+" is now following this wishlist!", "success"));
      console.log('handleAddGroupFollower - Launch blocker: fix error message here');
  } catch (error) {
    console.log(error);
    dispatch(showAlert("Oops! Something went wrong. Try again and contact support if you need assistance.", "error"));
  }
  }

  const handleClose = () => {
    console.log('THIS ABOUT IF THIS SHOULD CLEAR SANTA STATE - MAY DIFFER DEPENDING ON TYPE OF CLOSE i.e. NOT NOW vs CLOSE');
    dispatch(clearSecretSantaDetails());
  };

  return (
    <Dialog
      open={showAddSantaGroupFollowerModal || false}
      // TransitionComponent={Transition}
      fullScreen={fullScreen}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">Confirm your secret santa group details</DialogTitle>
      <DialogContent>

        <DialogContentText id="alert-dialog-slide-description">
          Are you a participant of the secret santa group <Box fontWeight="fontWeightBold" fontWeight='fontWeightMedium' display='inline'>{group_name} ({num_group_members} members, created by {matching_user_name})</Box>?  By confirming this, your secret santa will see your gift updates via occational digest emails.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          No, skip this
        </Button>
        <Button onClick={handleAddGroupFollower} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddSantaFollowerModal;
