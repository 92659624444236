import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../firebase";

import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const SettingsPage = () => {
  // redux state
  const isLoggingOut = useSelector(state => state.auth.isLoggingOut);
  const logoutError = useSelector(state => state.auth.logoutError);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  return (
    <Container maxWidth="md">
      <Box
        alignItems="center"
        textAlign="center"
        my={1}
        p={1}
      >
        <Typography component="h1" variant="h4">Settings</Typography>
        <button onClick={handleLogout}>Logout</button>
        {isLoggingOut && <p>Logging Out....</p>}
        {logoutError && <p>Error logging out</p>}
      </Box>
    </Container>
  );
}

export default SettingsPage;
