export const LISTEN_TO_WISHLIST_SUCCESS = "LISTEN_TO_WISHLIST_SUCCESS";
export const LISTEN_TO_GIFTS_SUCCESS = "LISTEN_TO_GIFTS_SUCCESS";

export const receivedWishlistUpdate = (wishlist, wishlist_id) => {
  console.log('receivedWishlistUpdate!!!!');
  return {
    type: LISTEN_TO_WISHLIST_SUCCESS,
    payload: {wishlist, wishlist_id}
  };
}

export const receivedGiftsUpdate = (gifts) => {
  console.log('receivedGiftsUpdate!!!!');
  return {
    type: LISTEN_TO_GIFTS_SUCCESS,
    payload: {gifts}
  };
}
