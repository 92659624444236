import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import useMediaQuery from '@material-ui/core/useMediaQuery';


// "If you are creating a modal dialog, you probably want to use the Dialog component rather than directly using Modal"
// https://material-ui.com/components/dialogs/#api

import { toggleAddGiftModal } from "../actions";
import { giftCreationInfoChanged } from "../actions";
import { clearGiftCreationInfo } from "../actions";
import { addGiftToWishlist } from "../firebase";
import { showAlert } from "../actions";



const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  fieldContainer: {
    margin: 10
  },
  priceCurrency: {
    paddingRight: 10
  },
  priceContainer: {
    flexDirection: 'row',
    alignContent: 'center'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddGiftModal = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


  // redux state
  const showAddGiftModal = useSelector(state => state.giftCreation.showAddGiftModal);
  const item_title = useSelector(state => state.giftCreation.item_title);
  const item_description = useSelector(state => state.giftCreation.item_description);
  const url = useSelector(state => state.giftCreation.url);
  const price_currency = useSelector(state => state.giftCreation.price_currency);
  const price = useSelector(state => state.giftCreation.price);
  const wishlist_id = useSelector(state => state.wishlist.wishlist_id);
  const totalGiftsAdded = useSelector(state => state.auth.user.totalGiftsAdded);
  const dispatch = useDispatch();

  const currencies = [
  {
    value: '',
    label: null,
  },
  {
    value: 'USD',
    label: 'USD ($)',
  },
  {
    value: 'EUR',
    label: 'EUR (€)',
  },
  {
    value: 'GBP',
    label: 'GBP (£)',
  },
  {
    value: 'AUD',
    label: 'AUD ($)',
  },
  {
    value: 'CAD',
    label: 'CAD ($)',
  },
];

  const handleClose = () => {
    dispatch(toggleAddGiftModal());
  };

  const handleSave = async () => {
    console.log('handleSave - start');
    try{
      const giftRef = await addGiftToWishlist({
        item_title,
        item_description,
        url,
        price_currency,
        price,
        wishlist_id,
        totalGiftsAdded
      });
      console.log('Success! You added a gift added to your wishlist!');
      console.log('gift id: ' + giftRef.id);
      dispatch(toggleAddGiftModal());
      dispatch(clearGiftCreationInfo());
      dispatch(showAlert("Success! You added a gift added to your wishlist!", "success"));
  } catch (error) {
    console.log("handleSave gift: " + error);
    dispatch(showAlert("Oops! Something went wrong. Trying adding that gift again.", "error"));
  }


  }

  const handleTextFieldChange = ({ target }) => {
    console.log(target.name + " = " + target.value );
    dispatch(giftCreationInfoChanged({ prop: target.name, value: target.value }));
  };

  return (
    <Dialog fullScreen={fullScreen} open={showAddGiftModal || false} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Add gift to wishlist
          </Typography>
          <Button autoFocus color="inherit" onClick={handleSave}>
            save
          </Button>
        </Toolbar>
      </AppBar>
      <Box className={classes.fieldContainer}>
        <TextField
          defaultValue={item_title}
          variant="outlined"
          margin="dense"
          fullWidth
          required
          id="item_title"
          label="Gift title"
          name="item_title"
          onChange={handleTextFieldChange}
        />
        <TextField
          defaultValue={url}
          variant="outlined"
          margin="dense"
          fullWidth
          id="url"
          label="URL (recommended)"
          name="url"
          onChange={handleTextFieldChange}
        />
        <TextField
          defaultValue={item_description}
          variant="outlined"
          margin="dense"
          fullWidth
          multiline
          rows={4}
          id="item_description"
          label="Description, size, color, etc"
          name="item_description"
          onChange={handleTextFieldChange}
        />
        <Box className={classes.priceContainer}>
          <TextField
            defaultValue={price_currency}
            className={classes.priceCurrency}
            id="outlined-select-currency-native"
            select
            margin="dense"
            label="Currency"
            name="price_currency"
            value={price_currency}
            onChange={handleTextFieldChange}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          >
            {currencies.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
          <TextField
            defaultValue={price}
            variant="outlined"
            margin="dense"
            id="price"
            type="number"
            label="Price"
            name="price"
            onChange={handleTextFieldChange}
          />
        </Box>
      </Box>
    </Dialog>
  );
// }
})

export default AddGiftModal;
