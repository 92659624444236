import {
  GIFT_DELETION_MODAL_OPEN,
  GIFT_DELETION_MODAL_CLOSE
} from "../actions/";


const INITIAL_STATE = {
  showDeleteGiftModal: false,
  item_title: '',
  gift_id: ''
}

export default ( state = INITIAL_STATE, action ) => {
  switch (action.type) {
    case GIFT_DELETION_MODAL_OPEN:
      return {
        ...state,
        showDeleteGiftModal: true,
        item_title: action.payload.item_title,
        gift_id: action.payload.gift_id,
      }

    case GIFT_DELETION_MODAL_CLOSE:
      return INITIAL_STATE;


    default:
      return state;
  }
};
