import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import {
  QueryParamProvider,
  ExtendedStringifyOptions,
  transformSearchStringJsonSafe,
} from 'use-query-params';

import App from "./App";
import configureStore from "./configureStore";

const store = configureStore();

const queryStringifyOptions: ExtendedStringifyOptions = {
  transformSearchString: transformSearchStringJsonSafe,
};

function Root() {
  return (
    <Provider store={store}>
      <Router>
        <QueryParamProvider
          ReactRouterRoute={Route}
          stringifyOptions={queryStringifyOptions}
        >
          <App />
        </QueryParamProvider>
      </Router>
    </Provider>
  );
}

export default Root;
