import {
  ADD_SANTA_GROUP_FOLLOWER_MODAL_TOGGLE,
  GET_SECRET_SANTA_REQUEST,
  GET_SECRET_SANTA_SUCCESS,
  SECRET_SANTA_INFO_PROVIDED,
  CLEAR_SECRET_SANTA
} from "../actions/";


const INITIAL_STATE = {
  haveRequestedDetailsOnce: false,
  gmid: null,
  group_name: null,
  matching_user_name: null,
  gid: null,
  num_group_members: null,
  showAddSantaGroupFollowerModal: false
}

export default ( state = INITIAL_STATE, action ) => {
  switch (action.type) {
    case GET_SECRET_SANTA_REQUEST:
      return {
        ...state,
        ...INITIAL_STATE,
        haveRequestedDetailsOnce: true,
        gmid: action.payload.gmid,
        gid: action.payload.gid
      };
    case GET_SECRET_SANTA_SUCCESS:
      return {
        ...state,
        group_name: action.payload.group_name,
        matching_user_name: action.payload.matching_user_name,
        num_group_members: action.payload.num_group_members,
      };

    case SECRET_SANTA_INFO_PROVIDED:
      return { ...state, [action.payload.prop]: action.payload.value };

    case ADD_SANTA_GROUP_FOLLOWER_MODAL_TOGGLE:
      return {
        ...state,
        showAddSantaGroupFollowerModal: !state.showAddSantaGroupFollowerModal
      };

    case CLEAR_SECRET_SANTA:
      return {
        ...INITIAL_STATE
      }

    default:
      return state;
  }
};
