
// take string as the query to search ebay with
// return the URL w/ ebay affilaite tag (global)
export const getEbaySearchURLWithAffilaite = (query) => {
  const regex = /\s/g;
  const ebaySearchUrl = 'https://rover.ebay.com/rover/1/705-53470-19255-0/1?mpre=https%3A%2F%2Fwww.ebay.com.au%2Fsch%2Fi.html%3F_from%3DR40%26_trksid%3Dp2380057.m570.l1311%26_nkw%3D' + query.replace(regex, '%2B') + '%26_sacat%3D0&campid=5338757039&toolid=10001&customid='
  return ebaySearchUrl;
}

// take string as the query to search amazon with and country
// return the URL w/ ebay affilaite tag (country specific)
export const getAmazonSearchURLWithAffilaite = (query) => {

  const regex = /\s/g;
  const amazonSearchUrl = 'https://www.amazon.com.au/s?k='+ query.replace(regex, '+') + '&tag=secretsanta07-22'
  return amazonSearchUrl;
}


// take url and return w/ ebay or amazon affilaite
export const addAffiliateTags = (url) => {
  if(url.includes('amazon') || url.includes('amzn')) {
    // australian vs us tag
    const tag = (url.includes('.com.au')) ?'secretsanta07-22' :'secretsant02d-20';
    // if first url param, use ? otherwise &
    const returnUrl = (url.includes('?')) ?url+'&tag='+tag :url + '?tag=' + tag;
    return returnUrl;
  }
  // else if(url.includes('ebay')) {
  //   const tag = '';
  //   console.log('addAffiliateTags: ' + url + ' ' + tag);
  //   return url + tag;
  // }
  return url;
}

// export const getCountryFromGeoIp = () => {
//   var geo_url = 'http://www.geoplugin.net/json.gp';
//   fetch(geo_url)
//   .then((response) => response.json())
//   .then((responseJson) => {
//     console.log('getCountryFromGeoIp: ' + responseJson.geoplugin_countryName);
//     return responseJson.geoplugin_countryName;
//   })
//   .catch((error) => {
//     console.error(error);
//   });
// }
