import {
  LOGIN_MODAL_TOGGLE,
  SIGNUP_MODAL_TOGGLE,

  SEND_LOGIN_EMAIL_REQUEST,
  SEND_LOGIN_EMAIL_SUCCESS,
  SEND_LOGIN_EMAIL_FAILURE,
  // VERIFY_EMAIL_LINK_FAILURE,

  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  CLEAR_LOGIN_ERROR,

  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,

  VERIFY_REQUEST,
  VERIFY_SUCCESS,

  LISTEN_TO_USER_PROFILE_SUCCESS,
  LISTEN_TO_USER_SUCCESS,
  LISTEN_TO_WISHLISTS_SUCCESS
} from "../actions/";

export default (
  state = {

    showLoginModal: false,
    showSignupModal: false,
    urlForLoginEmail: null,
    isAttemptingToSendLoginEmail: false,
    isAttemptingAuthentication: false,
    recentlySentLoginEmail: false,
    loginError: '',
    // verifyEmailLinkError: '',
    isLoggingOut: false,
    isVerifying: false,
    logoutError: false,
    isAuthenticated: false,
    userProfile: {},
    user: {},
    wishlists: {}
  },
  action
) => {
  switch (action.type) {
    case LOGIN_MODAL_TOGGLE:
    return {
      ...state,
      showLoginModal: !state.showLoginModal
    };
    case SIGNUP_MODAL_TOGGLE:
    return {
      ...state,
      showSignupModal: !state.showSignupModal
    };
    case LOGIN_SUCCESS:
    return {
      ...state,
      recentlySentLoginEmail: false,
      isAuthenticated: true
    };
    // probably useful if I add other types of login
    case LOGIN_FAILURE:
    return {
      ...state,
      recentlySentLoginEmail: false,
      isAuthenticated: false,
      loginError: action.payload
    };
    case CLEAR_LOGIN_ERROR:
    return {
      ...state,
      loginError: ''
    };
    case LOGOUT_REQUEST:
    return {
      ...state,
      isLoggingOut: true,
      logoutError: false
    };
    case LOGOUT_SUCCESS:
    return {
      ...state,
      isLoggingOut: false,
      isAuthenticated: false,
      userProfile: {},
      user: {},
      wishlists: {}
    };
    case LOGOUT_FAILURE:
    return {
      ...state,
      isLoggingOut: false,
      logoutError: true
    };
    case LISTEN_TO_USER_PROFILE_SUCCESS:
    return {
      ...state,
      userProfile: action.payload.userProfile
    };
    case LISTEN_TO_USER_SUCCESS:
    return {
      ...state,
      user: action.payload.user
    };
    case LISTEN_TO_WISHLISTS_SUCCESS:
    return {
      ...state,
      wishlists: action.payload.wishlists
    };
    default:
    return state;
  }
};
