import React, { useEffect } from "react";

import { Route, Switch, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { myFirebase } from "./firebase";
import { hotjar } from 'react-hotjar';



import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import HomePage from "./components/HomePage";
import WishlistPage from "./components/WishlistPage";
import SantaGroupPage from "./components/SantaGroupPage";
import SantasSecretKeeperPage from "./components/SantasSecretKeeperPage"
import LoginPage from "./components/LoginPage";
import WishlistCreationFlow from "./components/WishlistCreationFlow";
import PrivacyPolicyPage from "./components/PrivacyPolicyPage";
import SettingsPage from "./components/SettingsPage";
import AddSantaFollowerModal from "./components/AddSantaFollowerModal";
import Auth from "./components/Auth";


import ProtectedRoute from "./common/ProtectedRoute";
import NavBar from "./common/NavBar";
import Alerts from "./common/Alerts";
import LoginModal from "./common/LoginModal";
import AddGiftModal from "./common/AddGiftModal";

// Or Create your Own theme:
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4A80B5'
    }
  }
});

const App = (props) => {
  // redux state
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const isVerifying = useSelector(state => state.auth.isVerifying);

  const history = useHistory();
  useEffect(() => {
      trackScreenView(); // To track the first pageview upon load
      history.listen(() => {
        trackScreenView();
      }); // To track the subsequent pageviews
    }, [history]);

  const trackScreenView = () => {
    console.log("ScreenView Event", window.location.pathname);
    myFirebase.analytics().setCurrentScreen(window.location.pathname)
    myFirebase.analytics().logEvent('screen_view')
  }

  const initHotJar = () => {
    hotjar.initialize('2072446', '6');
  }

  return (
    <div>
      {initHotJar()}
      <MuiThemeProvider theme={theme}>
        <NavBar />
        <Alerts />
        <Auth />
        <LoginModal ref={React.createRef()} />
        <AddGiftModal ref={React.createRef()} />
        <Switch>
          <ProtectedRoute
            exact
            path="/settings"
            component={SettingsPage}
            isAuthenticated={isAuthenticated}
            isVerifying={isVerifying}
          />
          <Route path="/login" component={LoginPage} />
          <Route path="/create-wishlist/:step" component={WishlistCreationFlow} />
          <Route path="/create-wishlist" component={WishlistCreationFlow} />
          <Route path="/santas-secret-keeper/" component={SantasSecretKeeperPage} />
          <Route path="/privacy" component={PrivacyPolicyPage} />
          <Route path="/wishlist/:wishlist_id" component={WishlistPage} />
          <Route path="/group/:santa_group_id" component={SantaGroupPage} />
          <Route path="/" component={HomePage} />
        </Switch>
      </MuiThemeProvider>
    </div>
  );
}


export default App;
