import {
  LISTEN_TO_WISHLIST_SUCCESS,
  LISTEN_TO_GIFTS_SUCCESS
} from "../actions/";


const INITIAL_STATE = {
  wishlist_id: '',
  created_for: '',
  creator_first_name: '',
  creator_last_name: '',
  recipient_first_name: '',
  recipient_last_name: '',
  status: '',
  time_created_at: '',
  time_last_edit_at: '',
  creator_user_id: '',
  gifts: null,
  followers: null
}

const GIFTS_POSSIBLE_VALUES = {
  // this is not used, more just to keep track of fields in gifts
  creator_user_id: '',
  item_description: '',
  item_name: '',
  marchant: '',
  status: '',
  time_created_at: '',
  time_last_edit_at: '',
  url: '',
  price: null,
  price_currency: '',
  wishlist_id: ''
}

export default ( state = INITIAL_STATE, action ) => {
  switch (action.type) {
    case LISTEN_TO_WISHLIST_SUCCESS:
    return {
      ...state,
      wishlist_id: action.payload.wishlist_id,
      created_for: action.payload.wishlist.created_for,
      creator_first_name: action.payload.wishlist.creator_first_name,
      creator_last_name: action.payload.wishlist.creator_last_name,
      recipient_first_name: action.payload.wishlist.recipient_first_name,
      recipient_last_name: action.payload.wishlist.recipient_last_name,
      status: action.payload.wishlist.status,
      time_created_at: action.payload.wishlist.time_created_at,
      time_last_edit_at: action.payload.wishlist.time_last_edit_at,
      creator_user_id: action.payload.wishlist.creator_user_id,
      followers: action.payload.wishlist.followers
    };

    /*
    DOES IT MAKE SENSE TO UNPACK WISHLIST?
    SHOULD I UNPACK GIFTS?
    */

    case LISTEN_TO_GIFTS_SUCCESS:
    return {
      ...state,
      gifts: action.payload.gifts
    }

    default:
    return state;
  }
};
