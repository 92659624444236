import { combineReducers } from "redux";
import auth from "./auth";
import alerts from "./alerts";
import pendingGroupFollower from "./pendingGroupFollower";
import pendingUserProfile from "./pendingUserProfile";
import wishlist from "./wishlist";
import group from "./group";
import giftCreation from "./giftCreation";
import giftDeletion from "./giftDeletion";


export default combineReducers({
  auth,
  alerts,
  pendingGroupFollower,
  pendingUserProfile,
  wishlist,
  giftCreation,
  group,
  giftDeletion
});
