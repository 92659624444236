import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import PersonOutlineOutlined from "@material-ui/icons/PersonOutlineOutlined";
import Typography from "@material-ui/core/Typography";
import LinkMaterialUI from '@material-ui/core/Link';
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import CircularProgress from '@material-ui/core/CircularProgress';

import { clearLoginError } from "../actions";
import { attemptCreateAccount, attemptSignIn, attemptResetPassword } from "../firebase";
import { showAlert } from "../actions";

const useStyles = makeStyles({
  "@global": {
    body: {
      backgroundColor: "#fff"
    }
  },
  paper: {
    marginTop: 20,
    display: "flex",
    padding: 20,
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#f50057"
  },
  errorText: {
    color: "#f50057",
    marginBottom: 5,
    marginTop: 5,
    textAlign: "center"
  },
  termsText: {
    color: "#808080",
    marginBottom: 5,
    textAlign: "center",
    fontSize: 10
  },
  toggleLoginSignupText: {
    marginBottom: 5,
    marginTop: 5,
    textAlign: "center",
    fontSize: 15
  },
  toggleLoginSignupPasswordLinkText: {
    margin: 5,
    textAlign: "center",
    fontSize: 15
  },
  submitButton: {
    marginBottom: 5,
    marginTop: 5
  }
});

// why forwardRef?
// https://material-ui.com/guides/composition/#caveat-with-refs
// https://stackoverflow.com/questions/56347839/material-ui-v4-0-1-warning-expected-an-element-that-can-hold-a-ref
const LoginForm = React.forwardRef((props, ref)  => {

  // local state
  const [showSignup, setShowSignup] = useState(props.defaultToSignup); // could use to toggle between login / signup
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  // redux state
  const isAttemptingAuthentication = useSelector(state => state.auth.isAttemptingAuthentication);
  const loginError = useSelector(state => state.auth.loginError);
  const recentlySentLoginEmail = useSelector(state => state.auth.recentlySentLoginEmail);
  const dispatch = useDispatch();


  const classes = useStyles();

  useEffect(() => {
    // returned function will be called on component unmount
    return () => {
      dispatch(clearLoginError());
    }
  }, [showSignup])

  const handleEmailChange = ({ target }) => {
    setEmail(target.value);
  };

  const handlePasswordChange = ({ target }) => {
    setPassword(target.value);
  };

  const handleFirstNameChange = ({ target }) => {
    setFirstName(target.value);
  };

  const handleLastNameChange = ({ target }) => {
    setLastName(target.value);
  };


  const handleLoginButtonClick = async () => {
    // by default, login email takes user back to where they came
    dispatch(attemptSignIn(email, password));
  };

  const handleSignupButtonClick = async () => {
    // by default, login email takes user back to where they came
    dispatch(attemptCreateAccount({email, password, first_name: firstName, last_name: lastName}));
  };


  const handleToggleLoginSignup = () => {
    setShowSignup(!showSignup);
  }

  const handleTogglePasswordReset = () => {
    setShowPasswordReset(!showPasswordReset);
  }

  const handleResetPasswordButtonClick = async () => {
    const value = await attemptResetPassword(email);
    console.log(value);
    if(value == 'success') {
      dispatch(showAlert('Sent! Check your inbox (and spam folder) to reset your password.', "success"));
      setShowPasswordReset(false);
    } else {
      dispatch(showAlert(value, "error"));
    }
  }


  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          {
            (showSignup)
              ? (<PersonOutlineOutlined />)
              : (!showPasswordReset)
                ? (<LockOutlinedIcon />)
                : (<LockOutlinedIcon />)
          }
        </Avatar>
        <Typography component="h1" variant="h6" align="center">
          {
            (showSignup)
              ? props.signupTitle
              : (!showPasswordReset)
                ? props.loginTitle
                : "Reset your password"
          }
        </Typography>
        <Typography component="h1" variant="body1" align="center">
          {
            (showSignup)
              ? props.signupSubtitle
              : (!showPasswordReset)
                ? props.loginSubtitle
                : "Enter your email and we will send you an email with a link to reset your password"
          }
        </Typography>
        {
          (isAttemptingAuthentication) && (
            <CircularProgress className={classes.spinner} />
          )
        }
        {
          (!isAttemptingAuthentication) &&
          (showSignup &&
            <div>
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                id="first_name"
                label="First Name"
                name="first_name"
                onChange={handleFirstNameChange}
              />
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                id="last_name"
                label="Last Name"
                name="last_name"
                onChange={handleLastNameChange}
              />
            </div>
          )}
        {
          (!showPasswordReset)
            ? (
              <div>
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  onChange={handleEmailChange}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  onChange={handlePasswordChange}
                />
              </div>
            )
            : (
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                onChange={handleEmailChange}
              />
            )
        }

        {
          (loginError && !isAttemptingAuthentication )&& (
            <Typography component="p" className={classes.errorText}>
              {loginError}
            </Typography>
          )}
        {/* if need terms checkboxes, use small checkbox https://github.com/mui-org/material-ui/issues/10781 */}
        {/* removing because elfster and others dont seem to and I may not understand GDPR */}
        {(!showPasswordReset && (
          <div>
            <Typography style={{display: 'inline-block'}} component="p" className={classes.termsText}>
              By clicking 'Sign Up' or 'Login', you agree to this website's <Link to={'/privacy'} className={classes.termsText} >privacy policy</Link>
            </Typography>
          </div>
        ))}
        {
          (showSignup)
            ? (
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submitButton}
                onClick={handleSignupButtonClick}
              >
                Sign up
              </Button>
            )
            : (!showPasswordReset
              ? (
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  onClick={handleLoginButtonClick}
                >
                  Login
                </Button>
              )
              :(
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  onClick={handleResetPasswordButtonClick}
                >
                  Reset Password
                </Button>
              )
            )
        }
        {(!isAttemptingAuthentication) && ((showSignup)
          ?(
            <Typography style={{display: 'inline-block'}} component="p" className={classes.toggleLoginSignupText}>
              Already have an account? <LinkMaterialUI component="button" variant="body2" className={classes.toggleLoginSignupLinkText} onClick={handleToggleLoginSignup}>Login</LinkMaterialUI>
            </Typography>
          )
          : (!showPasswordReset)
            ?(
              <Typography style={{display: 'inline-block'}} component="p" className={classes.toggleLoginSignupText}>
                <LinkMaterialUI component="button" variant="body2" className={classes.toggleLoginSignupPasswordLinkText} onClick={handleToggleLoginSignup}>Sign Up</LinkMaterialUI>
                *
                <LinkMaterialUI component="button" variant="body2" className={classes.toggleLoginSignupPasswordLinkText} onClick={handleTogglePasswordReset}>Forgot Password</LinkMaterialUI>
              </Typography>
            )
            : (
              <Typography style={{display: 'inline-block'}} component="p" className={classes.toggleLoginSignupText}>
                <LinkMaterialUI component="button" variant="body2" className={classes.toggleLoginSignupPasswordLinkText} onClick={handleTogglePasswordReset}>Back to Login</LinkMaterialUI>
              </Typography>
            )
        )}
      </Paper>
    </Container>
        );
      })

      export default LoginForm;
