import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import isURL from 'validator/es/lib/isURL';
import copy from 'copy-to-clipboard';

import { makeStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardHeader from '@material-ui/core/CardHeader';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  getEbaySearchURLWithAffilaite, getAmazonSearchURLWithAffilaite, addAffiliateTags
} from "./Utils.js"
import { showAlert } from "../actions";

import { receivedGiftsUpdate } from "../actions";
import { toggleAddGiftModal } from "../actions";
import { openDeleteGiftModal, closeDeleteGiftModal } from "../actions";
import { listenToGiftsInWishlist, myAuth, myFirebase, deleteGift } from "../firebase";

const useStyles = makeStyles({
  card: {
    marginTop: 15,
    marginBottom: 15,
    marginRight:0,
    marginLeft:0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: '#F7F7F7',
    textAlign: "left"
  },
  buttonContainer: {
    textAlign: "center"
  }
});

const applyCharacterLimit = (string, limit) => {
  if(string.length > limit) {
    return string.substring(0,limit) + "...";
  } else {
    return string;
  }
}

const getCurrencySymbol = (price_currency) => {
  switch (price_currency) {
    case "USD":
    return "$"
    break;
    case "EUR":
    return "€"
    break;
    case "GBP":
    return "£"
    break;
    case "AUD":
    return "$"
    break;
    case "CAD":
    return "$"
    break;

    default:
    return "$"

  }
}



const GiftListItems = () => {

  const creator_user_id = useSelector(state => state.wishlist.creator_user_id);
  const wishlist_id = useSelector(state => state.wishlist.wishlist_id);
  const gifts = useSelector(state => state.wishlist.gifts);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const creator_first_name = useSelector(state => state.wishlist.creator_first_name);
  const dispatch = useDispatch();

  const showDeleteGiftModal = useSelector(state => state.giftDeletion.showDeleteGiftModal);
  const item_title_to_delete = useSelector(state => state.giftDeletion.item_title);
  const gift_id_to_delete = useSelector(state => state.giftDeletion.gift_id);

  const classes = useStyles();
  return Object.keys(gifts).map(gift_id => {
    const {
      item_title,
      item_description,
      merchant,
      price,
      price_currency,
      quantity
    } = gifts[gift_id];

    let {
      url
    } = gifts[gift_id];

    if(url && !(url.startsWith('http')) && isURL(url) && isURL('https://' + url)) {
      url = 'https://' + url;
    }

    if(url) {
      url = addAffiliateTags(url);
    }




    const logBuyClick = () => {
      myFirebase.analytics().logEvent('click_buy', {
        item_title: item_title,
        gift_id: gift_id,
        wishlist_id: wishlist_id
      });
    }

    const copyCardTitle = () => {
      myFirebase.analytics().logEvent('click_copy_gift', {
        item_title: item_title,
        gift_id: gift_id,
        wishlist_id: wishlist_id
      });

      if(item_description){
        copy(item_title + " - " + item_description);
        dispatch(showAlert("Copied '" + item_title + " - " + item_description + "' to your clipboard", "success"));
      } else {
        copy(item_title);
        dispatch(showAlert("Copied '" + item_title + "' to your clipboard", "success"));
      }
    }




    const ebaySearchUrl = getEbaySearchURLWithAffilaite(item_title);

    const searchEbayClick = () => {
      myFirebase.analytics().logEvent('click_search_ebay', {
        item_title: item_title,
        gift_id: gift_id,
        wishlist_id: wishlist_id
      });
    }

    const amazonSearchUrl = getAmazonSearchURLWithAffilaite(item_title);

    const searchAmazonClick = () => {
      myFirebase.analytics().logEvent('click_search_amazon', {
        item_title: item_title,
        gift_id: gift_id,
        wishlist_id: wishlist_id
      });
    }

    const getTitle = () => {
      if(price && price_currency){
        return item_title + ' (' + getCurrencySymbol(price_currency) + price + ')';
      } else {
        return item_title;
      }
    }

    const getSubTitle = () => {
      if(item_description) return item_description;
      return null;
    }

    const confirmDeleteGift = async () => {
      console.log('confirmDeleteGift');
      const value = await deleteGift(wishlist_id, gift_id_to_delete);
      if(value === true){
        dispatch(showAlert("Gift deleted from your wishlist", "success"));
      } else {
        dispatch(showAlert("Oops!  Unable to delete that gift.... try again", "error"));
      }
      toggleDeleteGiftModal();
    }

    const toggleDeleteGiftModal = () => {
      console.log('toggleDeleteGiftModal: ' + showDeleteGiftModal);
      if(showDeleteGiftModal){
        dispatch(closeDeleteGiftModal());
      } else {
        console.log(item_title);
        console.log(gift_id);
        dispatch(openDeleteGiftModal(gift_id, item_title));
      }
    }


    return (
      <div key={gift_id}>
        {
          <Card className={classes.card} key={gift_id}>
            {(isAuthenticated && creator_user_id == myAuth.currentUser.uid)
              ?(
                <CardHeader
                  action={
                    <div>
                      <IconButton aria-label="delete" onClick={toggleDeleteGiftModal}>
                        <DeleteOutlineIcon />
                      </IconButton>
                      <Dialog
                        open={showDeleteGiftModal}
                        onClose={toggleDeleteGiftModal}
                      >
                        <DialogTitle>{"Delete this gift?"}</DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Would you like to delete {item_title_to_delete} from your wishlist?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={toggleDeleteGiftModal} color="primary">
                            No
                          </Button>
                          <Button onClick={() => confirmDeleteGift(gift_id_to_delete, item_title_to_delete)} color="primary" autoFocus>
                            Yes
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  }
                  title={getTitle()}
                  subheader={getSubTitle()}
                />
              )
              : (
                <CardHeader
                  title={getTitle()}
                  subheader={getSubTitle()}
                />
              )
            }
            <CardContent>
              <Typography variant="body2" color='textSecondary' noWrap>
                {url}
              </Typography>
            </CardContent>
            {(url && isURL(url))
              ? (
                <CardActions>
                  <a target='_blank' rel='noopener' href={url} style={{ textDecoration: 'none' }}>
                    <Button variant="contained" size="small" color="primary" onClick={logBuyClick}>View</Button>
                  </a>
                </CardActions>
              )
              : (
                <CardActions>
                  {/* <Button variant="contained" size="small" color="primary" onClick={copyCardTitle}>Copy</Button> */}
                  <a target='_blank' rel='noopener' href={ebaySearchUrl} style={{ textDecoration: 'none' }}>
                    <Button variant="contained" size="small" color="primary" onClick={searchEbayClick}>Search Ebay</Button>
                  </a>
                  <a target='_blank' rel='noopener' href={amazonSearchUrl} style={{ textDecoration: 'none' }}>
                    <Button variant="contained" size="small" color="primary" onClick={searchAmazonClick}>Search Amazon</Button>
                  </a>
                </CardActions>
              )
            }
          </Card>
        }
      </div>
      )
    });
  }

  const GiftList = () => {
    // redux state
    const creator_user_id = useSelector(state => state.wishlist.creator_user_id);
    const wishlist_id = useSelector(state => state.wishlist.wishlist_id);
    const gifts = useSelector(state => state.wishlist.gifts);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const creator_first_name = useSelector(state => state.wishlist.creator_first_name);
    const dispatch = useDispatch();

    const classes = useStyles();

    useEffect(() => {
      // https://blog.logrocket.com/react-hooks-with-firebase-firestore/
      const observer = {
        next: querySnapshot => {
          let updatedGifts = {};
          querySnapshot.forEach(docSnapshot => {
            updatedGifts[docSnapshot.id] = docSnapshot.data();
          })
          dispatch(receivedGiftsUpdate(updatedGifts));
        },
        // error: () => setError('grocery-list-item-get-fail')
        error: () => console.log('ERROR in useEffect')
      };
      const unsubscribe = listenToGiftsInWishlist(wishlist_id, observer);
      // in useEffect, return a function that "cleans up the previous effects before applying the next effects"
      return unsubscribe;
    },
    // useEffect has optional second argument - the dependancy array
    // "You can tell React to skip applying an effect if certain values haven’t changed between re-renders"
    // this improves performance
    [wishlist_id, dispatch]
    // DO I NEED TO ADD WISHLIST OR GIFTS ITSELF?
    // SHOULD I ADD THIS OPTIMIZATION TO OTHER useEffect in other components?
    // "Any props or state variables that are used in the function from the first parameter need to be listed as dependencies"
    // https://blog.logrocket.com/react-hooks-with-firebase-firestore/
  );

  const handleAddGiftButtonClick = () => {
    dispatch(toggleAddGiftModal());
  }

  const renderGiftBoxZeroState = () => {
    if(isAuthenticated && creator_user_id == myAuth.currentUser.uid){
      return (
        <Container maxWidth="sm" >
          <Box
            alignItems="center"
            textAlign="center"
            my={2}
            p={1}
            border={1}
            borderColor='primary.main'
          >
            <Typography component="h1" variant="h6">
              Add your first gift!
            </Typography>
            <Typography variant="body2">
              1) Bookmark this page so you can easily return to your wishlist
            </Typography>
            <Typography variant="body2">
              2) Find a gift that you want from any website and copy the URL + name
            </Typography>
            <Typography variant="body2">
              3) Return to your wishlist and 'Add a gift'
            </Typography>
            <Typography variant="body2">
              4) Share your wishlist with anyone you want
            </Typography>
            <Box
              my={2}
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<AddCircleOutlineIcon />}
                className={classes.button}
                onClick={handleAddGiftButtonClick}
              >
                Add a gift
              </Button>
            </Box>
          </Box>
        </Container>
            )
          } else {
            return (
              <Container maxWidth="sm" >
                <Box
                  alignItems="center"
                  textAlign="center"
                  my={2}
                  p={1}
                  border={1}
                  borderColor='primary.main'
                >
                  <Typography component="h1" variant="h6">
                    0 gifts added
                  </Typography>
                  <Typography variant="body2">
                    {creator_first_name} has not added any gifts yet
                  </Typography>
                </Box>
              </Container>
              )
            }
          }

          const renderGiftBox =() => {
            if(gifts){
              const giftKeys = Object.keys(gifts);
              if(giftKeys.length > 0){
                return (
                  <div>
                    {(gifts.length > 2) && (
                      <div className={classes.buttonContainer}>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          startIcon={<AddCircleOutlineIcon />}
                          className={classes.button}
                          onClick={handleAddGiftButtonClick}
                        >
                          Add a gift
                        </Button>
                      </div>
                    )}
                    <GiftListItems gifts={gifts} />
                    { myAuth.currentUser && myAuth.currentUser.uid === creator_user_id && (
                      <div className={classes.buttonContainer}>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          startIcon={<AddCircleOutlineIcon />}
                          className={classes.button}
                          onClick={handleAddGiftButtonClick}
                        >
                          Add a gift
                        </Button>
                      </div>
                    )}
                  </div>
                    )
                  } else {
                    return renderGiftBoxZeroState();
                  }
                } else {
                  return renderGiftBoxZeroState();
                }
              }

              return (
                <Box>
                  {renderGiftBox()}
                </Box>
              );
            }

            export default GiftList;
