import {
  PENDING_USER_PROFILE_INFO_CHANGED
} from "../actions/";


const INITIAL_STATE = {
  firstName: '',
  lastName: ''
}

export default ( state = INITIAL_STATE, action ) => {
  switch (action.type) {
    case PENDING_USER_PROFILE_INFO_CHANGED:
      return { ...state, [action.payload.prop]: action.payload.value };

    default:
      return state;
  }
};
