import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import GroupIcon from '@material-ui/icons/Group';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';


import { receivedGroupUpdate } from "../actions";

import { listenToGroup, myAuth, myFirebase } from "../firebase";


const useStyles = makeStyles({
  constainer: {
    alignItems:"center",
    // paddingBottom: 50
  },
  card: {
    marginTop: 15,
    marginBottom: 15,
    display: "flex",
    flexDirection: "column",
    // alignItems: "flex-start",
    textAlign: "left"
  },
  buttonContainer: {
    textAlign: "center"
  }
});


const SantaGroupPage = () => {
  let { santa_group_id } = useParams();  // react router for required params


  // redux santa group state
  const santa_group_id_in_state = useSelector(state => state.group.santa_group_id);
  const following = useSelector(state => state.group.following);
  const group_name = useSelector(state => state.group.group_name);
  const matching_user_name = useSelector(state => state.group.matching_user_name);
  const matching_user_santa_uid = useSelector(state => state.group.matching_user_santa_uid);
  const num_group_members = useSelector(state => state.group.num_group_members);
  const price_max = useSelector(state => state.group.price_max);
  const price_min = useSelector(state => state.group.price_min);

  const gmid = useSelector(state => state.pendingGroupFollower.gmid);
  const pending_group_name = useSelector(state => state.pendingGroupFollower.group_name);
  const gid = useSelector(state => state.pendingGroupFollower.gid);

  const dispatch = useDispatch();


  const useStyles = makeStyles({
    constainer: {
      alignItems:"center",
      // paddingBottom: 50
    },
    card: {
      marginTop: 15,
      marginBottom: 15,
      display: "flex",
      flexDirection: "column",
      // alignItems: "flex-start",
      textAlign: "left"
    },
    buttonContainer: {
      textAlign: "center"
    },
    "buttonBox": {
      backgroundColor: "#f0ec90",
    },
    "button": {
      margin: 10
    }
  });

  const classes = useStyles();

  const history = useHistory();


  useEffect(() => {
    // https://blog.logrocket.com/react-hooks-with-firebase-firestore/
    const observer = {
      next: querySnapshot => {
        const updatedGroup = querySnapshot.data();
        dispatch(receivedGroupUpdate(updatedGroup, santa_group_id));
      },
      // error: () => setError('grocery-list-item-get-fail')
      error: () => console.log('ERROR in useEffect')
    };
    const unsubscribe = listenToGroup(santa_group_id, observer);
    // in useEffect, return a function that "cleans up the previous effects before applying the next effects"
    return unsubscribe;
  },
  // useEffect has optional second argument - the dependancy array
  // "You can tell React to skip applying an effect if certain values haven’t changed between re-renders"
  // this improves performance
  [santa_group_id, dispatch]
  // DO I NEED TO ADD WISHLIST OR GIFTS ITSELF?
  // SHOULD I ADD THIS OPTIMIZATION TO OTHER useEffect in other components?
  // "Any props or state variables that are used in the function from the first parameter need to be listed as dependencies"
  // https://blog.logrocket.com/react-hooks-with-firebase-firestore/
);


const renderGroupWishlists = () => {
  return Object.keys(following).sort().map(wishlist_id => {
    const {
      created_for,
      creator_first_name,
      creator_last_name,
      recipient_first_name,
      recipient_last_name
    } = following[wishlist_id];

    const handleSeeWishlistClick = () => {
      history.push('/wishlist/' + wishlist_id);
    };

    return (
      <Card className={classes.card} key={wishlist_id}>
        <CardActionArea onClick={() => handleSeeWishlistClick()}>
          <CardContent>
            <Typography gutterBottom variant="h5">
              {(recipient_first_name)
                ? (recipient_last_name)
                  ? recipient_first_name + " " + recipient_last_name + "'s wishlist"
                  : recipient_first_name + "'s wishlist"
                : creator_first_name + " " + creator_last_name + "'s wishlist"
              }
            </Typography>
          </CardContent>
          <CardActions>
            <Button variant="outlined" size="small" color="primary" onClick={handleSeeWishlistClick}>See Wishlist</Button>
          </CardActions>
        </CardActionArea>
      </Card>
    )
  })
}

const handleCreateNewWishlist = () => {
  history.push('/create-wishlist?gid='+gid+'&gmid='+gmid);
}

return (
  <Container maxWidth="sm" className={classes.constainer}>
    <Box
      my={1}
      p={1}
    >
      {!santa_group_id_in_state
        ?(
          <div>
            <Typography component="h1" variant="h4" align="center">
              Loading group...
            </Typography>
            <Typography component="h1" variant="subtitle1" align="center">
              This will just take a moment...
            </Typography>
            <CircularProgress />
          </div>
        )
        :(
          <div>
            <Typography component="h1" variant="h4" align="center">
              Wishlists from {group_name}
            </Typography>
            <Typography component="h1" variant="subtitle1" align="center">
              Secret Santa created by {matching_user_name} using <Link target='_blank' rel='noopener' href='https://www.santassecretkeeper.com/' variant="body2">Santa's Secret Keeper</Link>
            </Typography>

            <Box
              my={3}
              textAlign="center"
            >
              {following && Object.keys(following).length > 0
                ? renderGroupWishlists()
                : (<Typography>No wishlists yet (may be loading)</Typography>)
              }
            </Box>
          </div>
        )
      }

      <Box alignItems="center" textAlign="center" my={1} py={1} bgcolor="#e8f5e9">
        <Typography component="h1" variant="body1">
          Shared with {group_name} ({num_group_members})
        </Typography>
        <Typography component="h1" variant="body2">
          Secret Santa created by {matching_user_name}
        </Typography>
        {(gmid && gid && pending_group_name && gid == santa_group_id_in_state)
          ? (
            <Button variant="contained" type="button" onClick={handleCreateNewWishlist} color="primary" className={classes.button}>
              Create wishlist
            </Button>
          )
          : (
            <Box p={1} m={1}>
              <Typography variant="body2">
                Want to create a wishlist for this group? Go click your personlized <Box fontWeight="fontWeightBold" fontWeight='fontWeightMedium' display='inline'>Create and share your wishlist</Box> link from the Santas Secret Keeper emails
              </Typography>
            </Box>
          )
        }
      </Box>
    </Box>
  </Container>
    );
    }

    export default SantaGroupPage;
