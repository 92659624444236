import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';



import { myFirebase } from "../firebase";
import { logoutUser } from "../firebase";
import WishlistsList from './WishlistsList';


const HomePage = () => {
  // redux state
  const isLoggingOut = useSelector(state => state.auth.isLoggingOut);
  const logoutError = useSelector(state => state.auth.logoutError);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const isVerifying = useSelector(state => state.auth.isVerifying);
  const dispatch = useDispatch();
  let history = useHistory();

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const handleCreateNewWishlist = () => {
    history.push('/create-wishlist');
  }

  return (
    <Container maxWidth="md">
      <Box
        alignItems="center"
        textAlign="center"
        my={1}
        p={1}
      >
        <Typography component="h1" variant="h4">Welcome to Giftwish</Typography>
        <Box
          alignItems="center"
          textAlign="center"
          my={1}
          p={1}
        >
          <Typography component="h1" variant="body1">
            Giftwish lets you create wishlists for any occation
          </Typography>
        </Box>
        <Box
          alignItems="center"
          textAlign="center"
          my={1}
          p={1}
        >
          <Button
            variant="contained"
            type="button"
            onClick={handleCreateNewWishlist}
            color="primary"
          >
            Create new wishlist
          </Button>
        </Box>
        {isAuthenticated
          ?(
            <Box
              alignItems="center"
              textAlign="center"
              my={0}
              p={0}
            >
              <WishlistsList />
              <button onClick={handleLogout}>Logout</button>
            </Box>
          )
          :(
            <Box
              alignItems="center"
              textAlign="center"
              my={1}
              p={1}
            >
              <Link to="/login">Login</Link>
            </Box>
          )}
        {isLoggingOut && <p>Logging Out....</p>}
        {logoutError && <p>Error logging out</p>}
      </Box>
    </Container>
  );
}

export default HomePage;
