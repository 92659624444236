import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQueryParam, StringParam } from 'use-query-params';
import { useHistory } from "react-router-dom";

import { getSecretSantaDetails } from "../firebase";
import { myFirebase } from "../firebase";
import { requestingSecretSantaDetails } from "../actions";
import { receivedSecretSantaDetails } from "../actions";
import { showAlert } from "../actions";

const SantaURLToStateLoader = () => {
  //local state
  const [haveAttemptedLoad, setHaveAttemptedLoad] = useState(false);

  // redux state
  const haveRequestedDetailsOnce = useSelector(state => state.pendingGroupFollower.haveRequestedDetailsOnce);
  console.log('RETURN TO BEFORE LAUNCH: If someone with an active session tries to click link from ss email this may prevent it from being attempted');
  const group_name = useSelector(state => state.pendingGroupFollower.group_name);
  const matching_user_name = useSelector(state => state.pendingGroupFollower.matching_user_name);
  const gid = useSelector(state => state.pendingGroupFollower.gid);
  const num_group_members = useSelector(state => state.pendingGroupFollower.num_group_members);
  const dispatch = useDispatch();

  // optional url params
  const [gidParam, setGidParam] = useQueryParam('gid', StringParam);
  const [gmidParam, setGmidParam] = useQueryParam('gmid', StringParam);

  const executeGetSecretSantaDetails = async () => {
    dispatch(requestingSecretSantaDetails(gidParam, gmidParam));
    try {
      const results = await getSecretSantaDetails({
        gid: gidParam,
        gmid: gmidParam
      });
      myFirebase.analytics().logEvent('santa_details_received');
      // re-using same const names as state... but seems to work ok..
      // receivedSecretSantaDetails takes ordered params, not named params I guess
      const { group_name, num_group_members:num_group_members, matching_user_name:matching_user_name } = results.data;
      dispatch(receivedSecretSantaDetails(
        group_name,
        matching_user_name, num_group_members));
    } catch (error) {
      dispatch(showAlert('Oops! It looks like you might have been trying add a secret santa group as a follower but something went wrong. Try again.', "error"));
      console.log(error);
    }
  }

  useEffect(() => {
    if(gidParam && gmidParam && !haveRequestedDetailsOnce) {
      myFirebase.analytics().logEvent('santa_details_requested');
      executeGetSecretSantaDetails();
    }
  }, []); // passing empty array to useEffect means it wont run until new component mount (array of state to listen to changs to)


  return (
    <div>

    </div>
  );
}

export default SantaURLToStateLoader;
