export const GIFT_CREATION_MODAL_TOGGLE = "GIFT_CREATION_MODAL_TOGGLE";
export const GIFT_CREATION_INFO_CHANGED = "GIFT_CREATION_INFO_CHANGED";
export const CLEAR_GIFT_CREATION_INFO = "CLEAR_GIFT_CREATION_INFO";

export const toggleAddGiftModal = () => dispatch => {
  dispatch({ type: GIFT_CREATION_MODAL_TOGGLE })
};

export const giftCreationInfoChanged = ({ prop, value }) => dispatch => {
  dispatch ({
    type: GIFT_CREATION_INFO_CHANGED,
    payload: { prop, value }
  });
}

export const clearGiftCreationInfo = () => dispatch => {
  console.log('clearGiftCreationInfo');
  dispatch ({
    type: CLEAR_GIFT_CREATION_INFO
  });
}
