import { showAlert } from "../actions";

export const LOGIN_MODAL_TOGGLE = "LOGIN_MODAL_TOGGLE";
export const SIGNUP_MODAL_TOGGLE = "SIGNUP_MODAL_TOGGLE";

// specific to email link login flow
export const SEND_LOGIN_EMAIL_REQUEST = "SEND_LOGIN_EMAIL_REQUEST";
export const SEND_LOGIN_EMAIL_SUCCESS = "SEND_LOGIN_EMAIL_SEND_SUCCESS";
export const SEND_LOGIN_EMAIL_FAILURE = "SEND_LOGIN_EMAIL_SEND_FAILURE";
// export const VERIFY_EMAIL_LINK_FAILURE = "VERIFY_EMAIL_LINK_FAILURE";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
// probably useful if I add other types of login
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";

// userProfile, user, wishlists
export const LISTEN_TO_USER_PROFILE_SUCCESS = "LISTEN_TO_USER_PROFILE_SUCCESS";
export const LISTEN_TO_USER_SUCCESS = "LISTEN_TO_USER_SUCCESS";
export const LISTEN_TO_WISHLISTS_SUCCESS = "LISTEN_TO_WISHLISTS_SUCCESS";


export const toggleLoginModal = () => dispatch => {
  dispatch({ type: LOGIN_MODAL_TOGGLE })
};

export const toggleSignupModal = () => dispatch => {
  dispatch({ type: SIGNUP_MODAL_TOGGLE })
};


export const receiveLogin = user => {
  return {
    type: LOGIN_SUCCESS,
    user
  };
};

export const loginError = (error) => {
  return {
    type: LOGIN_FAILURE,
    payload: error.message
  };
};

export const clearLoginError = () => {
  return {
    type: CLEAR_LOGIN_ERROR
  };
};

export const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST
  };
};

export const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS
  };
};

export const logoutError = () => {
  return {
    type: LOGOUT_FAILURE
  };
};


export const receivedUserProfileUpdate = (userProfile) => {
  console.log('receivedUserProfileUpdate!!!!');
  return {
    type: LISTEN_TO_USER_PROFILE_SUCCESS,
    payload: {userProfile}
  };
}

export const receivedUserUpdate = (user) => {
  console.log('receivedUserUpdate!!!!');
  return {
    type: LISTEN_TO_USER_SUCCESS,
    payload: {user}
  };
}

export const receivedWishlistsUpdate = (wishlists) => {
  console.log('receivedWishlistsUpdate!!!!');
  return {
    type: LISTEN_TO_WISHLISTS_SUCCESS,
    payload: {wishlists}
  };
}
