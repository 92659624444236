export const GIFT_DELETION_MODAL_OPEN = "GIFT_DELETION_MODAL_OPEN";
export const GIFT_DELETION_MODAL_CLOSE = "GIFT_DELETION_MODAL_CLOSE";

export const openDeleteGiftModal = (gift_id, item_title) => dispatch => {
  dispatch({
    type: GIFT_DELETION_MODAL_OPEN,
    payload: { gift_id, item_title }
  })
};

export const closeDeleteGiftModal = () => dispatch => {
  dispatch({ type: GIFT_DELETION_MODAL_CLOSE })
};
