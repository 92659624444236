import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Settings from '@material-ui/icons/Settings';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';

import { toggleLoginModal} from "../actions";
import { toggleSignupModal } from "../actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function MenuAppBar() {
  const classes = useStyles();

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const isVerifying = useSelector(state => state.auth.isVerifying);
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = (openBool) => {
    console.log('toggleDrawer');
    setIsOpen(openBool);
  };

  let history = useHistory();

  const location = useLocation();
  const activeRoute = (routeName: any) => {
    return location.pathname === routeName ? true : false;
    return false;
  }

  const handleLoginButtonClick = () => {
    dispatch(toggleLoginModal());
  }

  const handleSignUpButtonClick = () => {
    dispatch(toggleSignupModal());
  }

  // const handleCreateWishlistButtonClick = () => {
  //   history.push("/create-wishlist");
  // }

  const handleMyWishlistButtonClick = () => {
    history.push("/");
  }

  const NavBarButtons = () => {

    if (isVerifying) {
      return null;
    }
    if(isAuthenticated){
      return (
        <div>
          {/* <Button
            color="inherit"
            onClick={handleCreateWishlistButtonClick}
          >Create Wishlist</Button> */}
          {location && location.pathname !== '/' && (
            <Button
              color="inherit"
              onClick={handleMyWishlistButtonClick}
            >My Wishlists</Button>
          )}
        </div>
      )
    } else {
      return (
        <div>
          <Button
            color="inherit"
            onClick={handleLoginButtonClick}
          >Login</Button>
          <Button
            color="inherit"
            onClick={handleSignUpButtonClick}
          >Sign Up</Button>
        </div>
      )
    }
  }

  const theme = useTheme();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Giftwish
          </Typography>
          <NavBarButtons />
        </Toolbar>
      </AppBar>
      <Drawer open={isOpen} onClose={() => toggleDrawer(false)}>

        <div
          role="presentation"
          onClick={() => toggleDrawer(false)}
          onKeyDown={() => toggleDrawer(false)}
        >
          <MenuList>

            {/* // this list and app routes could be abstracted to share code */}
            {/* // https://dev.to/rossanodan/building-a-navigation-drawer-with-material-ui-and-react-router-dom-1j6l */}

            {(isAuthenticated
              ?(
                <NavLink to="/" style={{ textDecoration: 'none', color: theme.palette.primary.main }} >
                  <MenuItem selected={activeRoute("/")}>
                    <ListItemText primary={"My Wishlists"} />
                  </MenuItem>
                </NavLink>
              )
              :(
                <NavLink to="/" style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
                  <MenuItem selected={activeRoute("/")}>
                    <ListItemText primary={"Home"} />
                  </MenuItem>
                </NavLink>
              ))}

            <NavLink to="/create-wishlist" style={{ textDecoration: 'none', color: theme.palette.primary.main }} >
              <MenuItem selected={activeRoute("/create-wishlist")}>
                <ListItemText primary={"Create a Wishlist"} />
              </MenuItem>
            </NavLink>

            {(isAuthenticated && (
              <NavLink to="/settings" style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
                <MenuItem selected={activeRoute("/settings")}>
                  <ListItemText primary={"Settings"} />
                </MenuItem>
              </NavLink>
            ))}

            <NavLink to="/privacy" style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
              <MenuItem selected={activeRoute("/privacy")}>
                <ListItemText primary={"Privacy Policy"} />
              </MenuItem>
            </NavLink>

          </MenuList>
        </div>
      </Drawer>
      <Box
        alignItems="center"
        textAlign="center"
        py={1}
        bgcolor="#e8f5e9"
      >
        <Link
          href="https://www.surveymonkey.com/r/X9J9LDV"
          target="_blank"
          rel="noopener noreferrer"
          marginTop="10"
          // onClick={preventDefault}
        >
          Giftwish.co is brand new! Please give me feedback by taking this 1 minute survey
        </Link>
      </Box>
    </div>
  );
}
