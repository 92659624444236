import {
  SHOW_ALERT,
  UPDATE_ALERT_DETAILS,
  HIDE_ALERT
} from "../actions/";

export default (
  state = {
    showAlert: false,
    message: null,
    // types include: error, warning, info, success
    type: null
  },
  action
) => {
  switch (action.type) {
    case SHOW_ALERT:
      return {
        ...state,
        showAlert: true
      };
    case UPDATE_ALERT_DETAILS:
          return { ...state, [action.payload.prop]: action.payload.value };
    case HIDE_ALERT:
      return {
        ...state,
        showAlert: false
      };
    default:
      return state;
  }
};
