import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { receivedGiftsUpdate } from "../actions";
import { toggleAddGiftModal } from "../actions";
import { myAuth } from "../firebase";


const useStyles = makeStyles((theme) => ({
  noSpacing: {
    marginLeft:0,
    marginRight:0,
    paddingRight:0,
    paddingLeft:0
  },
  constainer: {
    alignItems:"center"
    // paddingBottom: 50
  },
  card: {
    marginTop: 15,
    marginBottom: 15,
    marginRight: 0,
    marginLeft: 0,
    paddingRight: 0,
    paddingLeft: 0,
    display: "flex",
    flexDirection: "column",
    // alignItems: "flex-start",
    textAlign: "left"
  },
  buttonContainer: {
    textAlign: "center"
  }
}));

const applyCharacterLimit = (string, limit) => {
  if(string.length > limit) {
    return string.substring(0,limit) + "...";
  } else {
    return string;
  }
}

const WishlistsItems = ({wishlistsArray, gid, gmid}) => {
  const classes = useStyles();
  let history = useHistory();

  return wishlistsArray.map(wishlist => {
    const {
      created_for,
      creator_first_name,
      creator_last_name,
      recipient_first_name,
      recipient_last_name,
      status,
      time_created_at,
      time_last_edit_at,
      wishlist_id,
      followers
    } = wishlist;


    const handleSeeWishlistClick = () => {
      history.push('/wishlist/' + wishlist_id);
    };

    const handleSeeGroupClick = () => {
      history.push('/group/' + followers[Object.keys(followers)[0]].santa_group_id);
    };

    return (
      <Card classes={{ root: classes.card }} key={wishlist_id}>
        <CardActionArea onClick={() => handleSeeWishlistClick()}>

          <CardContent>
            <Typography gutterBottom variant="h5">
              {(recipient_first_name)
                ? (recipient_last_name)
                  ? recipient_first_name + " " + recipient_last_name + "'s wishlist"
                  : recipient_first_name + "'s wishlist"
                : creator_first_name + " " + creator_last_name + "'s wishlist"
              }
            </Typography>
            {
              // this only shows the first follower which is fine for now
            }
            {followers && Object.keys(followers).length > 0 && (
              <Typography component="h1" variant="subtitle2">
                {followers[Object.keys(followers)[0]].follower_name}
              </Typography>
            )}
          </CardContent>
          <CardActions>
            <Button variant="outlined" size="small" color="primary">View Wishlist</Button>
            {followers && Object.keys(followers).length > 0 && (
              <Button
                size="small"
                color="primary"
                onMouseDown={event => event.stopPropagation()}
                onClick={event => {
                  event.stopPropagation();
                  event.preventDefault();
                  handleSeeGroupClick()
                }}
              >View Group</Button>
            )}
          </CardActions>
        </CardActionArea>
      </Card>
    )
  });
}

const WishlistsList = (gid, gmid, group_wishlists) => {
  // redux state
  const wishlists = useSelector(state => state.auth.wishlists);
  const dispatch = useDispatch();

  const classes = useStyles();

  // const handleAddGiftButtonClick = () => {
  //   dispatch(toggleAddGiftModal());
  // }

  const keys = Object.keys(wishlists);
  let wishlistsArray = [];
  for(let i = 0; i < keys.length; i++){
    let value = wishlists[keys[i]];
    value['wishlist_id'] = keys[i];
    wishlistsArray.push(value);
  }


  return (
    <Container maxWidth="sm" className={classes.constainer} disableGutters={true}>
      {wishlistsArray && (
        <WishlistsItems wishlistsArray={wishlistsArray} gid={gid} gmid={gmid} />
      )}
      {/* { myAuth.currentUser.uid === creator_user_id && (
        <div className={classes.buttonContainer}>
        <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<AddCircleOutlineIcon />}
        className={classes.button}
        onClick={handleAddGiftButtonClick}
        >
        Add a gift
        </Button>
        </div>
      )} */}
    </Container>
);
}

export default WishlistsList;
