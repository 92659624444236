import React from "react";
import { useParams } from "react-router-dom";

import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const PrivacyPolicyPage = () => {
  return (
    <Container maxWidth="md">
      <Box
        alignItems="center"
        my={1}
        p={1}
      >
        <Typography component="h1" variant="h4" align="center">
          Privacy Policy
        </Typography>
        <Typography component="h1" variant="body1">
          Thomas Donahoe built the Giftwish as a Freemium application. This SERVICE is provided by Thomas Donahoe at no cost and is intended for use as is.
        </Typography>
        <Typography component="h1" variant="body1">
          This page is used to inform visitors regarding my policies with the collection, use, and disclosure of Personal Information if anyone decided to use my Service.
        </Typography>
        <Typography component="h1" variant="body1">
          If you choose to use my Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that I collect is used for providing and improving the Service. I will not use or share your information with anyone except as described in this Privacy Policy.
        </Typography>
      </Box>
      <Box
        alignItems="center"
        my={1}
        p={1}
      >
        <Typography component="h1" variant="h6" align="center">
          Information Collection and Use
        </Typography>
        <Typography component="h1" variant="body1">
          For a better experience, while using our Service, I may require you to provide us with certain personally identifiable information, including but not limited to name(s), email(s), country, device.
        </Typography>
        <Typography component="h1" variant="body1">
          The application does use third party services that may collect information used to identify you (Google Cloud / Firebase).
        </Typography>
      </Box>
      <Box
        alignItems="center"
        my={1}
        p={1}
      >
        <Typography component="h1" variant="h6" align="center">
          Log Data
        </Typography>
        <Typography component="h1" variant="body1">
          I want to inform you that whenever you use my Service, in a case of an error in the app I collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing my Service, the time and date of your use of the Service, and other statistics.
        </Typography>
      </Box>
      <Box
        alignItems="center"
        my={1}
        p={1}
      >
        <Typography component="h1" variant="h6" align="center">
          Security
        </Typography>
        <Typography component="h1" variant="body1">
          I value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and I cannot guarantee its absolute security.
        </Typography>
      </Box>
      <Box
        alignItems="center"
        my={1}
        p={1}
      >
        <Typography component="h1" variant="h6" align="center">
          Links to Other Sites​
        </Typography>
        <Typography component="h1" variant="body1">
          This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by me. Therefore, I strongly advise you to review the Privacy Policy of these websites. I have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
        </Typography>
        <Typography component="h1" variant="body1">
          This Service is a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to amazon.com, amazon.co.uk, amazon.ca and any other website that may be affiliated with Amazon Service LLC Associates Program.
        </Typography>
      </Box>
      <Box
        alignItems="center"
        my={1}
        p={1}
      >
        <Typography component="h1" variant="h6" align="center">
          Children’s Privacy
        </Typography>
        <Typography component="h1" variant="body1">
          These Services do not address anyone under the age of 13. I do not knowingly collect personally identifiable information from children under 13. In the case I discover that a child under 13 has provided me with personal information, I immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact me so that I will be able to do necessary actions.
        </Typography>
      </Box>
      <Box
        alignItems="center"
        my={1}
        p={1}
      >
        <Typography component="h1" variant="h6" align="center">
          Changes to This Privacy Policy
        </Typography>
        <Typography component="h1" variant="body1">
          I may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. I will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.
        </Typography>
      </Box>
    </Container>
  );
}

export default PrivacyPolicyPage;
