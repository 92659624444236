import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { receivedUserProfileUpdate } from "../actions";
import { receivedUserUpdate } from "../actions";
import { receivedWishlistsUpdate } from "../actions";


import { verifyAuth } from "../firebase";

import { receiveLogin } from "../actions";

import { myAuth, myFirestore, serverTimestamp } from "../firebase";


import { updateUserProfile } from "../firebase";

import { showAlert } from "../actions";
import { requestLoginEmail } from "../actions";
import { sentLoginEmail } from "../actions";
import { sendLoginEmailError } from "../actions";
import { requestLogout } from "../actions";
import { receiveLogout } from "../actions";
import { logoutError } from "../actions";

import { createInitialUser } from "../firebase";


const Auth = () => {

  const dispatch = useDispatch();

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  useEffect(() => {
    const unlistenAuthStateChanged = myAuth.onAuthStateChanged(user => {
      console.log('onAuthStateChanged');
      if (user) {
        dispatch(receiveLogin(user));
      }
    })
    return () => {
      console.log('unlistenAuthStateChanged');
      unlistenAuthStateChanged();
    }
  });

  useEffect(() => {
    if(isAuthenticated){
      // onSnapshot returns unsub method to detach a listener
      // If your effect returns a function, React will run it when it is time to clean up
      const unlistenUserProfiles = myFirestore.collection('userProfiles').doc(myAuth.currentUser.uid).onSnapshot(docSnapshot => {
        console.log("myFirestore.collection('userProfiles')");
        if(docSnapshot.exists) {
          dispatch(receivedUserProfileUpdate(docSnapshot.data()));
        }
      }, e => {
        console.log('ERROR LISTENING TO USERPROFILE: ' + e);
      });
      return () => {
        console.log('unlistenUserProfiles');
        unlistenUserProfiles();
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if(isAuthenticated){
      // onSnapshot returns unsub method to detach a listener
      // If your effect returns a function, React will run it when it is time to clean up
      const unlistenUsers = myFirestore.collection('users').doc(myAuth.currentUser.uid).onSnapshot(docSnapshot => {
        console.log("myFirestore.collection('users')");
        if(docSnapshot.exists) {
          dispatch(receivedUserUpdate(docSnapshot.data()));
        }
      }, e => {
        console.log('ERROR LISTENING TO USER: ' + e);
      });
      return () => {
        console.log('unlistenUsers');
        unlistenUsers();
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if(isAuthenticated){
      // onSnapshot returns unsub method to detach a listener
      // If your effect returns a function, React will run it when it is time to clean up
      const unlistenWishlists = myFirestore.collection('wishlists')
      .where('creator_user_id', '==', myAuth.currentUser.uid)
      .onSnapshot(querySnapshot => {
        console.log("myFirestore.collection('wishlists') query for all with my creator_user_id");
        if(!querySnapshot.empty) {
          let wishlists = {};
          querySnapshot.docs.map(doc => {
            wishlists[doc.id] = doc.data();
          });
          dispatch(receivedWishlistsUpdate(wishlists));
        }
      }, e => {
        console.log('ERROR LISTENING TO WISHLISTS: ' + e);
      });
      return () => {
        console.log('unlistenWishlists');
        unlistenWishlists();
      }
    }
  }, [isAuthenticated]);

  return (
    <div />
  );
}

export default Auth;
