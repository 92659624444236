import { myFirebase, myAuth, myFirestore, serverTimestamp } from "./";

import { updateUserProfile, addFieldsForCreate, getFieldsToUpdate } from "./";

import { showAlert } from "../actions";
import { receiveLogin } from "../actions";
import { requestLogout } from "../actions";
import { receiveLogout } from "../actions";
import { logoutError } from "../actions";
import { loginError } from "../actions";



export const logoutUser = () => dispatch => {
  dispatch(requestLogout());
  myAuth.signOut()
    .then(() => {
      dispatch(receiveLogout());
      dispatch(showAlert("You are signed out of your account", "success"));
    })
    .catch(error => {
      //Do something with the error if you want!
      dispatch(logoutError());
    });
};


export const createInitialUser = async ({first_name, last_name}) => {
  // Get a new write batch
  const batch = myFirestore.batch();

  const userRef = myFirestore.collection('users').doc(myAuth.currentUser.uid);
  batch.set(userRef,{
    email: myAuth.currentUser.email,
    time_last_write_at: serverTimestamp,
    time_create_user_at: serverTimestamp,
    totalGroupFollowersAdded: 0,
    totalWishlistsAdded: 0,
    totalGiftsAdded: 0
  });

  const userProfileRef = myFirestore.collection('userProfiles').doc(myAuth.currentUser.uid);
  // note: there is firebase/userProfile.js with similar code that is currently unused
  batch.set(userProfileRef,{
    first_name,
    last_name,
    email_not_trusted: myAuth.currentUser.email,
    time_created_at_inexact: serverTimestamp,
    time_last_edit_at: serverTimestamp
  });

  await batch.commit();

  return 'createInitialUser success';
}

export const attemptCreateAccount = ({
  email, password, first_name, last_name
})  => dispatch => {
  console.log('attemptCreateAccount');
  console.log(email);
  console.log(first_name);
  if (!first_name || !last_name) {
    dispatch(loginError({message: 'Your full name is required to create an account'}));
  } else {
    myAuth.createUserWithEmailAndPassword(email, password)
    .then(async ()=> {
      console.log('account created, creating initial user');
      const result = await createInitialUser({first_name, last_name});
      console.log(result);
      myFirebase.analytics().logEvent('sign_up');
    })
    .catch(error => {
    // Handle Errors here.
    console.log(error);
    if(error && error.message && error.message.startsWith('Failed to execute')){
      //https://github.com/firebase/firebase-js-sdk/issues/1533
      console.log('hard coded error for unsovled issue');
      dispatch(loginError({message: 'Oops - something went wrong. Try refreshing the page and try to login again'}))
    } else {
      dispatch(loginError(error))
    }
    });
  }
}


export const attemptSignIn = (email, password) => dispatch => {
  console.log('attemptSignIn');
  myAuth.signInWithEmailAndPassword(email, password)
  .then(async ()=> {
    console.log('signin success');
    myFirebase.analytics().logEvent('login');
  })
  .catch(error => {
  // Handle Errors here.
  console.log(error);
  if(error && error.message && error.message.startsWith('Failed to execute')){
    //https://github.com/firebase/firebase-js-sdk/issues/1533
    console.log('hard coded error for unsovled issue');
    dispatch(loginError({message: 'Oops - something went wrong. Try refreshing the page and try to login again'}))
  } else {
    dispatch(loginError(error))
  }
  });
}

export const attemptResetPassword = (email) => {
  console.log('attemptResetPassword');
  console.log(email);
  return myAuth.sendPasswordResetEmail(email).then(() => {
    myFirebase.analytics().logEvent('reset_password_sent');
    return 'success';
  }).catch((error) => {
    console.log('ffffffffffff');
    console.log(error);
    // An error happened.
    console.log(error.message);
    return error.message;
  });
}

// there is a ton of stuff that should be here but that is in the Auth component instead
// just didnt have time to figure out how to organize
