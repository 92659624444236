import { myAuth, myFirestore, myFirebase, myFunctions, serverTimestamp } from "./";
// import * as firebase from 'firebase';
import firebase from "firebase/app";

export const getSecretSantaDetails = async ({ gid, gmid }) => {
  let requestSanitizedSantaGroupDetails = myFunctions.httpsCallable('requestSanitizedSantaGroupDetails');
  return requestSanitizedSantaGroupDetails({gid, gmid});

  // Should I add catch error to all /firebase functions?
};

export const addGroupFollowerToWishlist = async ({
  group_name,
  matching_user_name,
  gid,
  num_group_members,
  gmid,
  wishlist_id,
  totalGroupFollowersAdded,

  created_for,
  creator_first_name, creator_last_name,
  recipient_first_name, recipient_last_name, // could be undefined
}) => {
  console.log('addGroupFollowerToWishlist - start');

  // Get a new write batch
  const batch = myFirestore.batch();

  const followerRef = myFirestore
  .collection('wishlists').doc(wishlist_id)
  .collection('followers').doc(gid);

  batch.set(followerRef,{
    creator_user_id: myAuth.currentUser.uid,
    follower_name: group_name,
    follower_description: num_group_members + ' participants',
    time_created_at: serverTimestamp,
    wishlist_id: wishlist_id, // needed for deleting follower when wishlistEnabled removed
    type: 'santa_group',
    // santa_group specific stuff
    santa_group_id: gid,
    matching_user_name: matching_user_name,
    wishlist_owner_gmid: gmid
  })


  const userRef = myFirestore
  .collection('users').doc(myAuth.currentUser.uid);

  batch.set(
    userRef,
    {
      totalGroupFollowersAdded: totalGroupFollowersAdded + 1,
      time_last_write_at: serverTimestamp
    },
    { merge: true }
  );

  const wishlistRef = myFirestore
  .collection('wishlists').doc(wishlist_id);

  batch.set(
    wishlistRef,
    {
      followers: {
        [followerRef.id]: {
          follower_name: group_name + ' (' + num_group_members + ' participants)',
          follower_description: "Secret Santa created by " + matching_user_name,
          santa_group_id: gid
        }
      }
    },
    { merge: true }
  );

  // const groupRef = myFirestore
  // .collection('santa_groups').doc(gid);
  //
  // let wishlist_following_object = {
  //   wishlist_owner_gmid: gmid,
  //   type: 'wishlist',
  //   wishlist_id,
  //   wishlist_owners_user_id: myAuth.currentUser.uid,
  //   time_created_at: serverTimestamp,
  //
  //   created_for,
  //   creator_first_name,
  //   creator_last_name
  // }
  //
  // if(recipient_first_name) wishlist_following_object.recipient_first_name = recipient_first_name;
  // if(recipient_last_name) wishlist_following_object.recipient_last_name = recipient_last_name;
  //
  // batch.set(
  //   groupRef,
  //   {
  //     following: {
  //       [wishlist_id]: wishlist_following_object
  //     }
  //   },
  //   { merge: true }
  // );



  await batch.commit(); // error is currently successfuly caught in AddSantaFollowerModal

  myFirebase.analytics().logEvent('follower_added', {
    type: 'santa_group'
  });

  return 1;
}
