import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useQueryParam, StringParam } from 'use-query-params';

import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CardGiftcardOutlined from '@material-ui/icons/CardGiftcardOutlined';
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from "@material-ui/core/Box";


import InputForm from '../common/InputForm';
import LoginForm from '../common/LoginForm';
import { showAlert } from '../actions';
import { pendingWishlistInfoChanged } from '../actions';
import { updatePendingWishlistFirstName } from '../actions';
import { pendingUserProfileInfoChanged } from '../actions';
import { secretSantaInfoProvided } from '../actions';
import { updateUserProfile } from "../firebase";
import { createWishlist } from "../firebase";

const useStyles = makeStyles({
  "@global": {
    body: {
      backgroundColor: "#fff"
    }
  },
  paper: {
    display: "flex",
    padding: 20,
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#f50057"
  },
  form: {
    marginTop: 1
  },
  errorText: {
    color: "#f50057",
    marginBottom: 5,
    textAlign: "center"
  },
  spinner: {
    marginTop: 20,
    marginBotom: 20
  }
});

// why forwardRef?
// https://material-ui.com/guides/composition/#caveat-with-refs
// https://stackoverflow.com/questions/56347839/material-ui-v4-0-1-warning-expected-an-element-that-can-hold-a-ref
const WishlistCreationFlow = React.forwardRef((props, ref)  => {
  let history = useHistory();
  /*
  steps:
  0) add-created-for
  1) add-name
  2) signup
  3) creating loading state
  */
  let { step } = useParams();  // react router for required params
  if(step == null) {
    step = 'add-created-for';
  }

  // params for wishlist creation itself
  const [createdForParam, setCreatedForParam] = useQueryParam('createdFor', StringParam); // useQueryParam for optional params
  const [firstNameForWishlistParam, setFirstNameForWishlistParam] = useQueryParam('firstNameForWishlist', StringParam); // useQueryParam for optional params
  const [lastNameForWishlistParam, setLastNameForWishlistParam] = useQueryParam('lastNameForWishlist', StringParam); // useQueryParam for optional params

  // params for pendingGroupFollower
  const [gmidParam, setGmidParam] = useQueryParam('gmid', StringParam);
  const [gidParam, setGidParam] = useQueryParam('gid', StringParam);

  // local state, wishlist form fields
  const [firstNameForWishlist, setFirstNameForWishlist] = useState('');
  const [lastNameForWishlist, setLastNameForWishlist] = useState('');
  const [isAttemptingCreation, setIsAttemptingCreation] = useState(false);

  // redux state for auth
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const userProfile = useSelector(state => state.auth.userProfile);
  const userLimits = useSelector(state => state.auth.user);
  const totalWishlistsAdded = useSelector(state => state.auth.user.totalWishlistsAdded);

  const dispatch = useDispatch();

  const classes = useStyles();


  const createWishlistHelper = async () => {
    console.log('createWishlistHelper');
    if(createdForParam) {
      try {

        let wishlistFields = {
          created_for: createdForParam,
          creator_first_name: userProfile.first_name,
          creator_last_name: userProfile.last_name,
          totalWishlistsAdded
        }
        if(firstNameForWishlistParam) wishlistFields.recipient_first_name = firstNameForWishlistParam;
        if(lastNameForWishlistParam) wishlistFields.recipient_last_name = lastNameForWishlistParam;

        const wishlistRef = await createWishlist(wishlistFields);
        console.log('wishlist has been created: ' + wishlistRef.id);

        let wishlistUrl = '/wishlist/' + wishlistRef.id;
        if(gmidParam && gidParam) {
          wishlistUrl = wishlistUrl + '?gid=' + gidParam + '&gmid=' + gmidParam;
        } else {
          // console.log('executeCreateWishlist: URL does NOT have gmid and gid');
        }

        history.push(wishlistUrl);
        setIsAttemptingCreation(false);
      } catch (error) {
        dispatch(showAlert("Oops! Something went wrong. Trying creating that wishlist again.", "error"));
        console.log('executeCreateWishlist error');
        console.log(error);
        setIsAttemptingCreation(false);
        history.push("/create-wishlist");
      }
    }
  }

  useEffect(() => {
    if (step === 'signup' && isAuthenticated && userProfile && (Object.keys(userProfile).length > 0)){
      history.push("/create-wishlist/creating" + getCurrentQueryParams());
    }
  }, [step, isAuthenticated, userProfile]);

  useEffect(() => {
    if (step === 'creating' && isAuthenticated && userProfile && (Object.keys(userProfile).length > 0) && !isAttemptingCreation) {
      if(totalWishlistsAdded !== 'undefined'){
        setIsAttemptingCreation(true);
        createWishlistHelper();
      } else {
        console.log('PROBLEM: totalWishlistsAdded NOT condition met - see comments in code');
        // not listening to totalWishlistsAdded bc that causes it to repeat before history.push
        // this may not work if redux pulls userProfile before totalWishlistsAdded
      }
    }
  }, [step, isAuthenticated, userProfile]);


  const getCurrentQueryParams = () => {
    let queryParams = '';
    if(gidParam && gmidParam){
      queryParams = '?gid=' + gidParam + '&gmid=' +  gmidParam;
    }
    if(createdForParam){
      if(queryParams===''){
        queryParams = '?createdFor='+createdForParam;
      } else {
        queryParams = queryParams + '&createdFor='+createdForParam;
      }
    }
    if(firstNameForWishlistParam){
      queryParams = queryParams + '&firstNameForWishlist=' + firstNameForWishlistParam;
    }
    if(lastNameForWishlistParam){
      queryParams = queryParams + '&lastNameForWishlist=' + lastNameForWishlistParam;
    }
    return queryParams;
  }

  const getCurrentAndAddNewQueryParams = (newParamsArray) => {
    // newParamsArray = [ { param = <PARAM_1>, value = <VALUE_1> }, { param = <PARAM_2>, value = <VALUE_1> }]
    let queryParams = getCurrentQueryParams();

    for(let obj of newParamsArray){
      if ('param' in obj && 'value' in obj) {
        if(queryParams === ''){
          queryParams = '?' + obj.param + '=' + obj.value;
        } else {
          queryParams = queryParams + '&' + obj.param + '=' + obj.value;
        }
      } else {
        console.log('ERROR: wrong param object format');
      }
    }
    return queryParams;
  }


  // when clicking button on 'add-created-for' step
  const handleCreatedForButtonClick = ({ currentTarget }) => {

    let newQueryParams = [{param: 'createdFor', value: currentTarget.value}];

    if(currentTarget.value != 'myself'){
      history.push("/create-wishlist/add-name" + getCurrentAndAddNewQueryParams(newQueryParams));
    } else {
      if(isAuthenticated){
        history.push("/create-wishlist/creating" + getCurrentAndAddNewQueryParams(newQueryParams));
      } else {
        history.push("/create-wishlist/signup" + getCurrentAndAddNewQueryParams(newQueryParams));
      }
    }
  };

  // typing letters on 'add-name' step
  const handleFirstNameForWishlistChange = ({ target }) => {
    setFirstNameForWishlist(target.value)
  };

  // typing letters on 'add-name' step
  const handleLastNameForWishlistChange = ({ target }) => {
    setLastNameForWishlist(target.value)
  };

  // when clicking continue button on 'add-name' step
  const handleAddNameButtonClick = () => {
    let newQueryParams = [];
    newQueryParams.push({param: 'firstNameForWishlist', value: firstNameForWishlist});
    if(lastNameForWishlist){
      newQueryParams.push({param: 'lastNameForWishlist', value: lastNameForWishlist});
    }

    if(isAuthenticated){
      history.push("/create-wishlist/creating" + getCurrentAndAddNewQueryParams(newQueryParams));
    } else {
      history.push("/create-wishlist/signup" + getCurrentAndAddNewQueryParams(newQueryParams));
    }
  };

  return (
    <Typography component="h1" variant="h6" align="center">
      {
        (step === 'add-created-for')
          ? (
            <InputForm
              title="Who is this wishlist for?"
              // subtitle="add-created-for"
              icon={CardGiftcardOutlined}
            >
              <div>
                <Button
                  type="button"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  value="myself"
                  className={classes.submit}
                  onClick={handleCreatedForButtonClick}
                  name="createdFor"
                >
                  Myself
                </Button>
                <Button
                  type="button"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  value="my_child"
                  className={classes.submit}
                  onClick={handleCreatedForButtonClick}
                  name="createdFor"
                >
                  My child
                </Button>
                <Button
                  type="button"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  value="someone_else"
                  className={classes.submit}
                  onClick={handleCreatedForButtonClick}
                  name="createdFor"
                >
                  Someone else
                </Button>
              </div>
              {!(gmidParam && gidParam) && (
                <Box
                  my={2}
                  p={1}
                >
                  <Typography variant="body2">
                    Hint: Trying to create a wishlist to be shared with your secret santa group?  Stop and go click your personlized <Box fontWeight="fontWeightBold" fontWeight='fontWeightMedium' display='inline'>Create and share your wishlist</Box> link from the Santas Secret Keeper emails
                  </Typography>
                </Box>
              )}
            </InputForm>
          )
          : (step === 'add-name')
            ? (
              <InputForm
                title="What is their name?"
                // subtitle="add-name"
                icon={CardGiftcardOutlined}
              >
                <div>
                  <TextField
                    required
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="firstNameForWishlist"
                    label="First name"
                    name="firstName"
                    value={firstNameForWishlist}
                    onChange={handleFirstNameForWishlistChange}
                  />
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="lastNameForWishlist"
                    label="Last name"
                    name="lastName"
                    value={lastNameForWishlist}
                    onChange={handleLastNameForWishlistChange}
                  />
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={firstNameForWishlist === ""}
                    onClick={handleAddNameButtonClick}
                  >
                    Continue
                  </Button>
                </div>
              </InputForm>
            )
            : (step === 'signup')
              ?(
                <LoginForm
                  ref={ref}
                  loginTitle="Welcome back"
                  loginSubtitle="Enter your email and password."
                  signupTitle="Create your wishlist"
                  signupSubtitle="Signup to create your wishlist."
                  defaultToSignup={true}
                  icon={CardGiftcardOutlined}
                  inWishlistCreation={true}
                  />
                )
                :(step === 'creating') && (
                  <InputForm
                    title="Creating your wishlist"
                    subtitle="Just one moment please"
                    icon={CardGiftcardOutlined}
                  >
                    <CircularProgress className={classes.spinner} />
                  </InputForm>
                )
      }
    </Typography>
  );
})
export default WishlistCreationFlow;
