export const GET_SECRET_SANTA_REQUEST = "GET_SECRET_SANTA_REQUEST";
export const GET_SECRET_SANTA_SUCCESS = "GET_SECRET_SANTA_SUCCESS";
export const SECRET_SANTA_INFO_PROVIDED = "SECRET_SANTA_INFO_PROVIDED";
export const ADD_SANTA_GROUP_FOLLOWER_MODAL_TOGGLE = "ADD_SANTA_GROUP_FOLLOWER_MODAL_TOGGLE";
export const CLEAR_SECRET_SANTA = "CLEAR_SECRET_SANTA";


export const requestingSecretSantaDetails = (gid, gmid) => {
  return {
    type: GET_SECRET_SANTA_REQUEST,
    payload: {gid, gmid}
  };
};

export const receivedSecretSantaDetails = (group_name, matching_user_name, num_group_members) => {
  return {
    type: GET_SECRET_SANTA_SUCCESS,
    payload: {group_name, matching_user_name, num_group_members}
  };
};

export const secretSantaInfoProvided = ({ prop, value }) => dispatch => {
  dispatch ({
    type: SECRET_SANTA_INFO_PROVIDED,
    payload: { prop, value }
  });
}

export const toggleAddSantaGroupFollowerModal = () => dispatch => {
  dispatch({ type: ADD_SANTA_GROUP_FOLLOWER_MODAL_TOGGLE })
};

export const clearSecretSantaDetails = () => dispatch => {
  dispatch({ type: CLEAR_SECRET_SANTA })
}
