import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";
import { firebaseConfig } from '../config';

export const myFirebase = firebase.initializeApp(firebaseConfig, 'myFirebase');
export const myAuth = myFirebase.auth();
export const myFirestore = myFirebase.firestore();
export const myFunctions = myFirebase.functions();
export const myAnalytics = myFirebase.analytics();


export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp()

export * from "./auth";
export * from "./userProfiles";
export * from "./wishlists";
export * from "./followers";
export * from "./gifts";
export * from "./groups";
