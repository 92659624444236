import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from '@material-ui/core/Modal';
// "If you are creating a modal dialog, you probably want to use the Dialog component rather than directly using Modal"
// https://material-ui.com/components/dialogs/#api

import LoginForm from './LoginForm';
import { toggleSignupModal } from "../actions";
import { toggleLoginModal } from "../actions";

// why forwardRef?
// https://material-ui.com/guides/composition/#caveat-with-refs
// https://stackoverflow.com/questions/56347839/material-ui-v4-0-1-warning-expected-an-element-that-can-hold-a-ref
const LoginModal = React.forwardRef((props, ref) => {
  // redux state
  const showSignupModal = useSelector(state => state.auth.showSignupModal);
  const showLoginModal = useSelector(state => state.auth.showLoginModal);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const dispatch = useDispatch();


  const handleClose = () => {
    if(showSignupModal) {
      dispatch(toggleSignupModal());
    } else {
      dispatch(toggleLoginModal());
    }
  };

  if (isAuthenticated) {
    return null;
  } else {
    return (
      <Modal
        open={showLoginModal || showSignupModal || false}
        onClose={handleClose}
      >
        <LoginForm
          ref={ref}
          loginTitle="Welcome back"
          loginSubtitle="Enter your email and password"
          signupTitle="Create your account"
          signupSubtitle="Enter your info to create your account."
          defaultToSignup={showSignupModal}
        />
      </Modal>
      );
    }
  });

  export default LoginModal;
