import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import LoginForm from '../common/LoginForm';

// why forwardRef?
// https://material-ui.com/guides/composition/#caveat-with-refs
// https://stackoverflow.com/questions/56347839/material-ui-v4-0-1-warning-expected-an-element-that-can-hold-a-ref
const LoginPage = React.forwardRef((props, ref)  => {

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);


  if (isAuthenticated) {
    return <Redirect to="/" />;
  } else {
    return (
      <LoginForm
        ref={ref}
        loginTitle="Welcome back."
        loginSubtitle="Enter your email and password"
        signupTitle="Signup with email"
        signupSubtitle="Enter your info to create your account."
        defaultToSignup={false}
      />

    );
  }
})

export default LoginPage;
